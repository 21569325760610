import axios, { AxiosResponse } from 'axios';
import {
  BASE_URL,
  CLOUDINARY_API_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_URL,
} from '../../env';
import { Equipment } from '../interfaces/Equipment';
import { Files, IFileDetails } from '../interfaces/File';
import { message } from 'antd';

const axiosGet = async (
  url: string,
  params?: { [key: string]: any }
): Promise<AxiosResponse> => {
  return axios.get(url, params);
};

export const getEquipmentDetails = async (
  equipmentId: string
): Promise<Equipment> => {
  const apiEndpoint = `${BASE_URL}/equipments/${equipmentId}`;
  return axiosGet(apiEndpoint, {})
    .then((response) => {
      const { data }: { data: Equipment } = response;
      return Promise.resolve(data);
    })
    .catch((response) => {
      console.log('Error: While Fetching Equipment Details ', response);
      return Promise.reject(response);
    });
};

export const getFileDetails = async (
  fileId: string,
  pageNumber: number
): Promise<IFileDetails> => {
  const fileDetailsApi = `${BASE_URL}/files/${fileId}?page_number=${pageNumber}`;
  return axiosGet(fileDetailsApi, {})
    .then((response) => {
      const { data }: { data: IFileDetails } = response;
      return Promise.resolve(data);
    })
    .catch((response) => {
      console.log('Error: While Fetching File Details ', response);
      return Promise.reject(response);
    });
};

export const getFiles = async (): Promise<Files[]> => {
  const getFiles = `${BASE_URL}/files`;
  return axiosGet(getFiles, {})
    .then((response) => {
      const { data }: { data: Files[] } = response;
      return Promise.resolve(data);
    })
    .catch((response) => {
      console.log('Error: While Fetching Files ', response);
      return Promise.reject(response);
    });
};

export const fileUploadHelper = async (fileMeta: any) => {
  return axios.post(`${BASE_URL}/files`, fileMeta);
};

export const deleteFileHelper = async (fileId: string) => {
  return axios.delete(`${BASE_URL}/files/${fileId}`);
};

export const getEquipments = async (): Promise<Equipment[]> => {
  const getEquipments = `${BASE_URL}/equipments`;
  return axiosGet(getEquipments, {})
    .then((response) => {
      const { data }: { data: Equipment[] } = response;
      return Promise.resolve(data);
    })
    .catch((response) => {
      console.log('Error: While Fetching Files ', response);
      return Promise.reject(response);
    });
};

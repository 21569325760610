import {
  FileImageFilled,
  FileImageTwoTone,
  FileOutlined,
  FilePdfTwoTone,
  InboxOutlined,
  SendOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Spin,
  Upload,
  UploadProps,
  message,
  Input,
  Avatar,
  UploadFile,
  Table,
} from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BASE_URL,
  CLOUDINARY_API_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_URL,
} from '../../env';
import { IChats } from '../../interfaces/chats.interface';
import { IDocument, IDocuments } from '../../interfaces/documents.interface';
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';
import './Dropbox.scss';
import menu, { MenuProps } from 'antd/es/menu';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { logout } from '../../state/reducers/authReducer/authReducer';
import { setChatId } from '../../state/reducers/chatReducer';
import { IDrawings } from '../../interfaces/drawings.interface';
import Papa from 'papaparse';
import DocumentViewModal from '../Documents/Components/DocumentViewModal';

const Dropbox = () => {
  const { Search } = Input;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const [chats, setChats] = useState<Array<IChats>>([]);
  const [documents, setDocuments] = useState<IDocuments>();
  const [drawings, setDrawings] = useState<Array<IDrawings>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [question, setQuestion] = useState('');
  const [isAsking, setIsAsking] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState('');

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '3') {
      dispatch(logout(''));
    }
  };

  const menu = (
    <Menu
      onClick={onClick}
      items={[
        {
          label: (
            <span className="flex flex-col">
              <span className="font-semibold">{user.username}</span>
              {user.email}
            </span>
          ),
          key: '0',
        },
        {
          type: 'divider',
        },
        {
          label: <span>Log Out</span>,
          key: '3',
        },
      ]}
    />
  );

  const getChatThreads = async () => {
    let url = BASE_URL + '/chats';
    setLoader(true);

    await axios
      .request({
        method: 'get',
        url,
      })
      .then((response) => {
        setChats(response.data);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
        setLoader(false);
      });
  };

  const getDocuments = async () => {
    let url = BASE_URL + '/documents';
    setIsLoading(true);
    await axios
      .get(url)
      .then((res) => {
        console.log(res.data, 'documents data');
        setDocuments(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        message.error(err.message);
      });
  };

  const getDrawings = async () => {
    let url = BASE_URL + '/drawings';
    setIsLoading2(true);
    await axios
      .get(url)
      .then((res) => {
        console.log(res.data, 'drawings data');
        setDrawings(res.data);
        setIsLoading2(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading2(false);
        message.error(err.message);
      });
  };

  useEffect(() => {
    getChatThreads();
    getDocuments();
    getDrawings();
  }, []);

  const handleDocumentCardClick = async (doc: IDocument) => {
    setTitle(doc.name);
    setVisible(true);
    setLoading(true);

    setPresignedUrl(doc.presigned_download_url);

    if (doc.type.split('/')[1] === 'pdf') {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };

  const uploadNewDrawing = async (
    fileName: string,
    fileType: string,
    cloudinaryUrl: string
  ) => {
    let newUrl = cloudinaryUrl;
    if (fileType === 'application/pdf') {
      const extensionIndex = cloudinaryUrl.lastIndexOf('.');
      newUrl = cloudinaryUrl.substring(0, extensionIndex) + '.png';
    }

    let url = BASE_URL + `/drawings`;
    let values = {
      title: fileName,
      cloudinary_url: newUrl,
    };
    await axios
      .post(url, values)
      .then((res) => {
        console.log(res.data, 'upload new drawing resp');
        setUploading(false);
        navigate(`/drawings/${res.data.id}`); // navigating to the newly created drawing details page
      })
      .catch((err) => {
        console.log(err, 'upload new drawing error');
      });
  };

  const props: UploadProps = {
    name: 'file',
    action: CLOUDINARY_URL,
    data: (file: UploadFile) => ({
      upload_preset: CLOUDINARY_UPLOAD_PRESET,
      api_key: CLOUDINARY_API_KEY,
      file,
    }),
    multiple: false,
    showUploadList: false,
    accept: 'application/pdf, image/png',
    beforeUpload: (file) => {
      setUploading(true);
      const isSupported =
        file.type === 'application/pdf' || file.type === 'image/png';
      if (!isSupported) {
        message.error(`${file.name} is not supported`);
        setUploading(false);
      }
      return isSupported;
    },
    onChange(info) {
      const file = info.file;
      if (file.status !== 'uploading') {
        console.log(file, 'uploaded file');
      }
      if (file.status === 'done') {
        message.success(`${file.name} file uploaded successfully`);
        uploadNewDrawing(file.name, file.type!, file.response.secure_url);
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  const createNewChat = async () => {
    message.loading({
      content: 'Asking question...',
      duration: 0,
      key: 'asking',
    });
    let url = BASE_URL + '/chats';
    setIsAsking(true);
    await axios
      .post(url, { title: question })
      .then((res) => {
        console.log(res.data, 'create new chat');
        message.destroy('asking');
        setIsAsking(false);
        navigate(`/chats/${res.data.id}`);
        //getChatThreads();
      })
      .catch((err) => {
        console.log(err);
        setIsAsking(false);
        message.destroy('asking');
        message.error(err.message);
      });
  };

  return (
    <div className="flex flex-col items-center w-[100vw] h-[100vh]">
      <div className="flex items-center justify-between w-full py-4 pl-20 pr-5">
        <img
          src="https://maximl.com/wp-content/uploads/2020/10/MaximlLOGO.png"
          alt="maximl-logo"
          className="h-4"
        />
        <div>
          {/* <span className="text-lg font-semibold">Chat</span>
          <span className="font-bold text-[#2351E5] text-lg">PDF</span> */}
          <div className="flex items-center justify-between">
            <img
              src="https://res.cloudinary.com/dzsifr04l/image/upload/v1683143304/text-1683143149235_ry619p.png"
              alt=""
              className="h-6 mr-10"
            />
            <Dropdown overlay={menu} trigger={['click']}>
              <div
                onClick={(e) => e.preventDefault()}
                className="flex items-center cursor-pointer"
              >
                <Avatar
                  className="hover:scale-110 transition ease-in-out delay-100"
                  style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                >
                  {user.username?.substring(0, 1)}
                </Avatar>
                <span className="ml-2">{user.username}</span>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="flex h-[50vh] w-full justify-center items-center bg-[url('https://res.cloudinary.com/dzsifr04l/image/upload/v1686163401/athena_background_utzu3a.gif')] bg-no-repeat bg-contain bg-[#000000] bg-center">
        {/* <div className="flex items-center justify-center w-1/2 h-full mx-40">
          <div className="flex flex-col text-white">
            <span className="mb-1 text-3xl font-bold">
              Digitally Connect Your Workforce
            </span>
            <span className="mb-1 text-3xl font-bold">
              and Machines with Maximl's
            </span>
            <span className="text-[#FEB909] text-3xl font-bold">
              Connected Worker Platform
            </span>
            <span className="text-[16px] font-normal mt-4 text-justify w-2/3">
              Industry leaders use Maximl to enhance field operations with
              digital workflows and standardization. Empower your frontline
              workforce to improve production and grow revenue.
            </span>
          </div>
        </div>
        <div className="flex items-center w-1/2 p-10">
          <img
            src="https://maximl.com/wp-content/uploads/2023/04/Hero-Image-Home.png"
            alt=""
            className="h-4/5"
          />
        </div> */}
        <div className="w-[60%] p-1 bg-white rounded-xl">
          <Search
            size="large"
            placeholder="Ask me a question"
            enterButton={question !== '' && !isAsking && <SendOutlined />}
            // enterButton={question === '' || isAsking ? null : <SendOutlined />}
            readOnly={isAsking}
            onSearch={(e) => {
              if (question !== '' && !isAsking) {
                createNewChat();
              } else if (isAsking) {
                message.warning('question already asked!');
              } else {
                message.warning('question should not be empty!');
              }
            }}
            onChange={(e: any) => setQuestion(e.target.value)}
          />
        </div>
      </div>
      <div className="flex h-[40vh] justify-between w-full p-4">
        <div className="w-1/2 border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4 mr-2 mb-2 mt-3">
          <span className="text-lg font-bold">My Queries</span>
          <div className="flex flex-wrap w-full max-h-[80%] mt-2 overflow-y-auto">
            {!loader ? (
              chats.slice(0, 10).map((chat: IChats) => {
                let truncatedText =
                  chat.last_message?.slice(0, 90) +
                  (chat?.last_message?.length > 90 ? '...' : '');
                return (
                  <div className="w-1/2 h-[106px] pr-2 mb-2">
                    <div
                      className="flex flex-col min-w-[200px] h-full border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4 cursor-pointer"
                      onClick={() => {
                        navigate(`/chats/${chat.id}`);
                      }}
                    >
                      <p className="font-bold">{chat.title}</p>
                      <span className="mt-2 text-[12px]">{truncatedText}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center w-full h-40">
                <Spin />
              </div>
            )}
          </div>
        </div>
        <div className="flex-col justify-between w-1/2 my-3">
          <div className="w-full h-1/2 border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4 mb-1">
            <div className="flex items-center justify-between w-full">
              <div className="text-lg font-bold">Recent Documents</div>
              <div
                className="font-semibold text-blue-400 cursor-pointer"
                onClick={() => {
                  navigate('/documents');
                }}
              >
                Go to All Documents
              </div>
            </div>
            {/* <div className="flex h-[80%] items-center w-full mt-2 overflow-x-auto"> */}
            <div className="flex w-full mt-2 overflow-x-auto">
              {isLoading ? (
                <div className="flex items-center justify-center w-full">
                  <Spin />
                </div>
              ) : (
                documents?.data?.slice(0, 10).map((doc: IDocument) => {
                  let truncatedText =
                    doc.name?.slice(0, 20) +
                    (doc.name?.length > 20 ? '...' : '');
                  return (
                    <div className="w-1/3 max-w-[1/3] pr-2 cursor-pointer">
                      <div
                        className="flex items-center w-full border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4 mb-2"
                        onClick={() => handleDocumentCardClick(doc)}
                      >
                        <FilePdfTwoTone
                          twoToneColor={'red'}
                          className="text-[18px] pr-1.5"
                        />

                        <div
                          className="max-w-[180px] truncate"
                          title={doc.name}
                        >
                          {doc.name}
                        </div>

                        {/* <span>{truncatedText}</span> */}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="w-full h-1/2 border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4">
            <div className="flex items-center justify-between w-full">
              <div className="text-lg font-bold">Recent Drawings</div>
              <Upload {...props}>
                <Button
                  type="primary"
                  ghost
                  //size="small"
                  icon={<UploadOutlined />}
                  loading={uploading}
                  disabled={isLoading2}
                >
                  New Drawing
                </Button>
              </Upload>
            </div>
            <div className="flex w-full mt-2 overflow-x-auto">
              {isLoading2 ? (
                <div className="flex items-center justify-center w-full">
                  <Spin />
                </div>
              ) : (
                drawings?.slice(0, 10).map((doc: any) => {
                  return (
                    <div className="w-1/3 max-w-[1/3] pr-2 cursor-pointer">
                      <div
                        className="flex items-center w-full border-[0.5px] border-solid border-[#D9D9D9] rounded-md p-4 mb-2"
                        onClick={() => {
                          navigate(`/drawings/${doc.id}`);
                        }}
                      >
                        <FilePdfTwoTone
                          twoToneColor={'red'}
                          className="text-[18px] pr-1.5"
                        />

                        <div
                          className="max-w-[180px] truncate"
                          title={doc.title}
                        >
                          {doc.title}
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <DocumentViewModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        setLoading={setLoading}
        title={title}
        isPdf={isPdf}
        presignedUrl={presignedUrl}
      />
    </div>
  );
};

export default Dropbox;

import React, { FC, useEffect, useState } from 'react';
import '@react-sigma/core/lib/react-sigma.min.css';
import Graph, { MultiDirectedGraph } from 'graphology';
import {
  SigmaContainer,
  useLoadGraph,
  useRegisterEvents,
  useSetSettings,
  useSigma,
} from '@react-sigma/core';
import { parse } from 'graphology-gexf';
import { Attributes } from 'graphology-types';
import { SigmaEdgeEventPayload, SigmaNodeEventPayload } from 'sigma/sigma';
import { generateRandomHexColor } from '../utils/GraphHelper';
interface GraphEventsHandler {
  onNodeClick?: (event: SigmaNodeEventPayload, nodeDisplayData: any) => void;
  onEdgeClick?: (event: SigmaEdgeEventPayload) => void;
}
const GraphEventsHandler: FC<GraphEventsHandler> = ({
  onNodeClick,
  onEdgeClick,
}) => {
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const setSettings = useSetSettings();
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [draggedNode, setDraggedNode] = useState<string | null>(null);
  useEffect(() => {
    registerEvents({
      rightClickNode: onNodeClick
        ? (event) => {
            setDraggedNode(null);
            event.event.original.preventDefault();
            // if (!draggedNode) {
            const nodeDisplayData = sigma.getNodeDisplayData(event.node);
            onNodeClick(event, nodeDisplayData);
            // }
          }
        : (event: SigmaNodeEventPayload) => {
            console.log('Clicked on node ', event);
          },
      // clickNode: onNodeClick
      //   ? (event) => {
      //       // if (!draggedNode) {
      //       const nodeDisplayData = sigma.getNodeDisplayData(event.node);
      //       onNodeClick(event, nodeDisplayData);
      //       // }
      //     }
      //   : (event: SigmaNodeEventPayload) => {
      //       console.log('Clicked on node ', event);
      //     },
      clickEdge: onEdgeClick
        ? onEdgeClick
        : (event: SigmaEdgeEventPayload) => {
            console.log('Clicked on edge  ', event);
          },
      enterNode: (event: SigmaNodeEventPayload) => setHoveredNode(event.node),
      leaveNode: (event: SigmaNodeEventPayload) => setHoveredNode(null),
      downNode: (e) => {
        setDraggedNode(e.node);
        sigma.getGraph().setNodeAttribute(e.node, 'highlighted', true);
      },
      mouseup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          sigma.getGraph().removeNodeAttribute(draggedNode, 'highlighted');
        }
      },
      mousedown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      mousemove: (e) => {
        if (draggedNode) {
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          sigma.getGraph().setNodeAttribute(draggedNode, 'x', pos.x);
          sigma.getGraph().setNodeAttribute(draggedNode, 'y', pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
      touchup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          sigma.getGraph().removeNodeAttribute(draggedNode, 'highlighted');
        }
      },
      touchdown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      touchmove: (e: any) => {
        if (draggedNode) {
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          sigma.getGraph().setNodeAttribute(draggedNode, 'x', pos.x);
          sigma.getGraph().setNodeAttribute(draggedNode, 'y', pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
    });
  }, [registerEvents, hoveredNode, draggedNode]);

  useEffect(() => {
    setSettings({
      nodeReducer: (node, data) => {
        const graph = sigma.getGraph();
        const newData: Attributes = {
          ...data,
          highlighted: data.highlighted || false,
        };

        if (hoveredNode) {
          if (
            node === hoveredNode ||
            graph.neighbors(hoveredNode).includes(node)
          ) {
            newData.highlighted = true;
          } else {
            newData.color = '#E2E2E2';
            newData.highlighted = false;
          }
        }
        return newData;
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, hidden: false };

        if (hoveredNode && !graph.extremities(edge).includes(hoveredNode)) {
          newData.hidden = true;
        }
        return newData;
      },
    });
  }, [hoveredNode, setSettings, sigma]);

  return null;
};

export default GraphEventsHandler;

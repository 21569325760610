import { useEffect, useState } from 'react';
import { getEquipmentDetails } from '../../utils/api.helper';
import { Equipment, ReferenceFile } from '../../interfaces/Equipment';
import { Avatar, List, Spin, Tabs, TabsProps } from 'antd';
import './Equipment.scss';
import { FilePdfTwoTone, FolderFilled, TagsFilled } from '@ant-design/icons';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

interface EquipmentDeatilsProps {
  equipmentId: string;
}
const EquipmentDeatils: React.FC<EquipmentDeatilsProps> = ({ equipmentId }) => {
  const [equipment, setEquipment] = useState<Equipment>();
  const [referenceFiles, setReferenceFiles] = useState<ReferenceFile[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [refTab, setRefTab] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    getEquipmentDetails(equipmentId).then((response: Equipment) => {
      const result: ReferenceFile[] = [];
      if (response?.referenced_files && response?.referenced_files.length) {
        const group = groupBy(response?.referenced_files, 'id');
        Object.keys(group).forEach((response) => {
          result.push(sortBy(group[response], 'page_number')[0]);
        });
      }
      setReferenceFiles(result);
      setEquipment(response);
      setIsLoading(false);
    });
  }, []);

  const EquipmentTags: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <TagsFilled /> Reference Files
        </>
      ),
    },
    {
      key: '2',
      label: (
        <>
          <FolderFilled /> Jobs
        </>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="justify-items-center flex flex-col items-center place-content-center w-[100%] equipment-drawer-container">
          <Spin />
          <h3>Fetching Equipment</h3>
        </div>
      ) : (
        <div className="flex flex-col">
          {/* <h3>{equipment?.equipment_tag}</h3> */}
          {equipment?.equipment_description && (
            <h3 className="mt-4">{equipment?.equipment_description}</h3>
          )}
          {!equipment?.equipment_description && (
            <h3 className="mt-5 text-gray-300">Equipment Description</h3>
          )}
          <Tabs
            className="mt-12"
            defaultActiveKey="1"
            items={EquipmentTags}
            onChange={(key) => {
              if (key === '1') {
                setRefTab(true);
              } else setRefTab(false);
            }}
          />
          {refTab && (
            <List
              itemLayout="horizontal"
              dataSource={referenceFiles}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    key={item.id}
                    avatar={<FilePdfTwoTone size={24} />}
                    title={
                      <a
                        href={`${window.location.origin}/file/${item.id}?page_num=${item.page_number}`}
                        target="_blank"
                      >
                        {item.title}
                      </a>
                    }
                    // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      )}
    </>
  );
};

export default EquipmentDeatils;

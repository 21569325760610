import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L, { icon } from 'leaflet';
import { Avatar, Badge } from 'antd';

const MarkerWithBadge = (props: any) => {
  return (
    <Marker
      ref={(ref) => {
        if (ref) {
          ref.setIcon(
            L.divIcon({
              html: `<span style ="display: inline-block;
                    ">
                    <span style="display: inline-flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-top: 0.2rem;
                    padding-bottom: 0.2rem;
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                    color:  ${props.textColor};
                    background-color: ${props.badgeColor};
                    font-size: 0.7rem;
                    line-height: 1rem;
                    font-weight: 500;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px;
                    ">${props.badgeCount}</span>
                  </span>`,
              className: 'leaflet-div-icon-custom',
              iconSize: [25, 20],
            })
          );
        }
      }}
      {...props}
    />
  );
};

export default MarkerWithBadge;

import {
  InfoCircleOutlined,
  SendOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Input, Modal, Popover, Select, SelectProps, Tag, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import axios from 'axios';
import { BASE_URL, CHAT_SOURCES_DISPLAY } from '../../env';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import './Chat.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { setRefreshRequired } from '../../state/reducers/chatReducer';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { IChat } from '../../interfaces/chat.interface';
import { IMessage } from '../../interfaces/message.interface';
import MessageSources from './Components/MessageSources';
import MessageImageSources from './Components/MessageImageSources';

const Chat = () => {
  const { Search } = Input;
  const [width, height] = useWindowSize();
  const params = useParams();

  const initialChat: IChat = {
    id: '',
    title: '',
    source: '',
    session_id: null,
    messages: [],
    tags: [],
  };
  const [chat, setChat] = useState<IChat>(initialChat);
  const [currentText, setCurrentText] = useState<string>('');
  const [input, setInput] = useState('');
  const [question, setQuestion] = useState('');
  const [streamingChatId, setStreamingChatId] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const initialChatId = useAppSelector(({ chat }) => chat.chatId);
  const [visible, setVisible] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<SelectProps['options']>([]);
  const [tmpOptions, setTmpOptions] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  const getChatData = async () => {
    setChat(initialChat);
    let url = BASE_URL + '/chats/' + params.id;
    message.loading({
      content: 'Loading...',
      duration: 0,
      key: 'loading_msg',
    });
    await axios
      .request({
        method: 'get',
        url,
      })
      .then((response) => {
        setChat(response.data);
        message.destroy('loading_msg');
      })
      .catch((e) => {
        message.error(e.response.data.message);
        message.destroy('loading_msg');
      });
  };

  useEffect(() => {
    if (params.id) {
      getChatData();
    }

    // called whenever we switch to different chat
    return () => {
      //setCurrentText('');
      setIsStreaming(false);
      setInput('');
      setQuestion('');
    };
  }, [params]);

  const createNewChat = async (msg: string) => {
    let url = BASE_URL + '/chats';
    let payload: any = {
      title: msg,
    };
    if (selectedTags.length) {
      payload['tags'] = selectedTags;
    }
    await axios
      .post(url, payload)
      .then((res) => {
        //dispatch(setRefreshRequired(true));
        navigate(`/chats/${res.data.id}`);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const streamingResponse = async (payload: {
    chatId: string;
    content: string;
  }) => {
    let url = BASE_URL + '/chats/get_response';

    const token = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('x-tenant-id');

    await fetchEventSource(url, {
      method: 'POST',
      headers: {
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
        'x-tenant-id': `${tenantId}`,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      openWhenHidden: true,
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log('Connection made ', res);
          setCurrentText('');
          setStreamingChatId(payload.chatId);
          if (initialChatId !== payload.chatId) {
            dispatch(setRefreshRequired(true));
          }
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log('Client side error ', res);
        }
        return Promise.resolve();
      },
      onmessage(event) {
        //console.log(event, 'event object');

        const info = event.data;

        if (info.startsWith(`{"response"`)) {
          const parsedData = JSON.parse(info);

          setChat((prevChat: any) => {
            if (prevChat.id === parsedData['response']['chatId']) {
              const updatedMessages = [...prevChat.messages];
              const lastMessageIndex = updatedMessages.length - 1;
              const lastMessage = { ...updatedMessages[lastMessageIndex] };

              lastMessage.sources = parsedData['response']['sources'];

              lastMessage.image_sources =
                parsedData['response']['image_sources'];

              lastMessage.content = parsedData['response']['content'];
              updatedMessages[lastMessageIndex] = lastMessage;

              return { ...prevChat, messages: updatedMessages };
            } else {
              return prevChat;
            }
          });
        } else {
          setCurrentText((prevText) => prevText + info);
        }
      },
      onclose() {
        console.log('Connection closed by the server');
        setIsStreaming(false);
      },
      onerror(err) {
        console.log('There was an error from server', err);
        setIsStreaming(false);
      },
    });
  };

  useEffect(() => {
    if (
      chat?.id?.length &&
      streamingChatId.length &&
      streamingChatId === chat.id
    ) {
      setChat((prevChat: any) => {
        const updatedMessages = [...prevChat.messages];
        if (updatedMessages.length > 0) {
          const lastMessageIndex = updatedMessages.length - 1;
          const lastMessage = { ...updatedMessages[lastMessageIndex] };
          if (lastMessage.created_by?.username !== 'AI Bot') {
            return {
              ...prevChat,
              messages: [
                ...updatedMessages,
                { content: currentText, created_by: { username: 'AI Bot' } },
              ],
            };
          } else {
            lastMessage.content = currentText; // Modify the content of the last message
            lastMessage.created_by = { username: 'AI Bot' };
            updatedMessages[lastMessageIndex] = lastMessage;

            return { ...prevChat, messages: updatedMessages };
          }
        }
      });
    }
  }, [currentText]);

  // useEffect(() => {
  //   if (params.id) {
  //     try {
  //       socket.on('OnResponse', (payload) => {
  //         if (payload.chatId == params.id) {
  //           const message: IMessage = {
  //             content: payload.content,
  //             sources: payload.sources,
  //             created_by: payload.author,
  //             last_updated_by: payload.last_updated_by,
  //           };

  //           setChat((prevChat: any) => ({
  //             ...prevChat,
  //             messages: [...prevChat?.messages, message],
  //           }));

  //           setBotLoader(false);
  //         }
  //       });
  //     } catch (error: any) {
  //       console.log('error data', error.message);
  //     }
  //   }

  //   return () => {
  //     socket.removeAllListeners('OnResponse');
  //   };
  // }, [params]);

  const myRef: any = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll

  //useMountEffect(executeScroll);
  useEffect(() => {
    executeScroll();
  }, [chat]);

  const handleSearch = () => {
    //   scrollToBottom();
    if (question.trim() !== '') {
      setIsStreaming(true);
      const message: IMessage = {
        created_at: new Date().toISOString(),
        last_updated_at: new Date().toISOString(),
        extra_info: null,
        content: question,
        created_by: user,
        last_updated_by: user,
      };

      const dummy_message: IMessage = {
        created_at: new Date().toISOString(),
        last_updated_at: new Date().toISOString(),
        extra_info: null,
        content: '...',
        created_by: { username: 'AI Bot' },
        last_updated_by: user,
      };

      setChat({
        ...chat,
        messages: [...chat?.messages, message, dummy_message],
      });
      if (params.id === undefined) {
        createNewChat(question);
        setQuestion('');
      } else {
        const payload = {
          chatId: params.id,
          content: question,
        };

        streamingResponse(payload);
        setQuestion('');
      }
    }
  };

  useEffect(() => {
    // making streaming api call with chat title in case of new chat
    if (chat?.id?.length && chat.messages.length == 0) {
      setQuestion(chat.title);
    }
  }, [chat]);

  useEffect(() => {
    handleSearch();
  }, [question]);

  const getAllTagsList = async () => {
    let url = BASE_URL + '/tags';
    await axios
      .get(url)
      .then((res) => {
        const arr: SelectProps['options'] = [];
        res.data.map((obj: any) => {
          arr.push({
            value: obj.name,
            label: obj.name,
          });

          tmpOptions.push(obj.name);
        });
        setTagsOptions(arr);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    if (params.id === undefined) {
      getAllTagsList();
    }
  }, [params]);

  const addTag = async (payload: { [name: string]: string }) => {
    let url = BASE_URL + '/tags';
    await axios
      .post(url, payload)
      .then((res) => {
        console.log(res.data, 'post tag resp');
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const handleChange = (values: Array<string>) => {
    values.map((val: string) => {
      const value = val.trim();
      if (!tmpOptions.includes(value)) {
        setTmpOptions([...tmpOptions, value]);
        addTag({ name: value });
      }
    });

    setSelectedTags(values);
  };

  const handleSave = () => {
    chat.tags = selectedTags;
    setVisible(false);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div
        className={`flex flex-col justify-between w-full p-6 ${
          params.id === undefined && chat.messages.length == 0
            ? `bg-[url('assets/NewChatBg.svg')] bg-no-repeat bg-center bg-[length:800px_500px]`
            : `bg-none`
        }`}
        style={{ height: height - 80 }}
      >
        {params.id === undefined &&
          chat.messages.length == 0 &&
          chat.tags.length == 0 && (
            <SettingOutlined
              style={{ fontSize: '30px' }}
              onClick={() => setVisible(true)}
            />
          )}

        <div className={`chat-log relative w-full overflow-y-auto`}>
          {chat.tags.length > 0 && (
            <Popover
              placement="rightTop"
              title={<p className="text-[16px] font-bold">Tags:</p>}
              content={
                <div className="flex-row flex-wrap max-w-sm">
                  {chat.tags.map((tag: string) => (
                    <Tag className="mb-2">{tag}</Tag>
                  ))}
                </div>
              }
              //trigger="click"
            >
              <InfoCircleOutlined
                style={{ fontSize: '27px', color: '#1E88E5' }}
              />
            </Popover>
          )}
          <div>
            {chat?.messages?.map((message: IMessage, index: number) => {
              return (
                <div
                  className={`p-3 mb-4 border-[1px] border-solid border-[#e0e0e0] rounded-md w-fit max-w-[75%] ${
                    message.created_by?.username !== 'AI Bot'
                      ? 'ml-auto bg-blue-500 text-white border-none text-[16px]'
                      : message.content === '' || message.content === '...'
                      ? 'border-none'
                      : 'markdown-body'
                  }`}
                >
                  {message.content !== '...' && message.content !== '' ? (
                    <>
                      <ReactMarkdown
                        children={message.content}
                        remarkPlugins={[remarkGfm]}
                      />
                      {message.image_sources &&
                        message.image_sources.length > 0 && (
                          <MessageImageSources
                            sources={message.image_sources}
                          />
                        )}
                      {message.sources && message.sources.length > 0 && (
                        <MessageSources sources={message.sources} />
                      )}
                    </>
                  ) : (
                    <div className="p-2 mt-2">
                      <img
                        src="https://res.cloudinary.com/dzsifr04l/image/upload/v1683149600/ezgif.com-crop_1_ywrjpa.gif"
                        height={40}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div ref={myRef}></div>
        </div>

        <div className="bottom-0 mt-1">
          <Search
            placeholder="Ask me a question"
            disabled={isStreaming}
            autoFocus={true}
            enterButton={
              <div>
                <SendOutlined />
              </div>
            }
            onSearch={() => {
              setQuestion(input);
              setInput('');
            }}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            size="large"
          />
        </div>
      </div>
      <Modal
        visible={visible}
        title={'Select Tags'}
        //footer={null}
        closable={false}
        onCancel={() => setVisible(false)}
        onOk={handleSave}
        cancelButtonProps={{ style: { display: 'none' } }}
        centered
        width={'40%'}
        //bodyStyle={{ height: '70%' }}
      >
        <div className="my-5">
          <Select
            mode="tags"
            allowClear
            style={{ width: '90%' }}
            placeholder="Tags"
            onChange={handleChange}
            options={tagsOptions}
          />
          {/* <Button type="primary" onClick={handleSave}>
            Save
          </Button> */}
        </div>
      </Modal>
    </div>
  );
};

export default Chat;

import { combineReducers } from '@reduxjs/toolkit';
import apiReducer from './apiReducer/apiReducer';
import authReducer from './authReducer/authReducer';
import { paginationApi } from './apiReducer/slices/paginationSlice';
import chatReducer from './chatReducer';
import drawingReducer from './drawingReducer';
import layoutReducer from './layout.reducer';

const reducers = combineReducers({
  [paginationApi.reducerPath]: paginationApi.reducer,
  api: apiReducer,
  auth: authReducer,
  chat: chatReducer,
  drawing: drawingReducer,
  layout: layoutReducer,
});

export default reducers;

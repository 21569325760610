import { TabsProps } from 'antd';
import { FileExtractContentTypes, MarkerType } from './interfaces/File';

export enum SearchCategory {
  All = 'All',
  Equipment = 'Equipment',
  Drawing = 'Drawing',
  PID = 'P&ID',
  Datasheet = 'Datasheet',
  Other = 'Other',
}

export const CategoryMeta: any = {
  equipment: {
    color: '#2db7f5',
    name: SearchCategory.Equipment,
  },
  'p&id': {
    color: '#87d068',
    name: SearchCategory.PID,
  },
  drawing: {
    color: '#f50',
    name: SearchCategory.Drawing,
  },
  datasheet: {
    color: '#108ee9',
    name: SearchCategory.Datasheet,
  },
  other: {
    color: 'orange',
    name: SearchCategory.Other,
  },
};

export const searchCategories: TabsProps['items'] = [
  {
    key: SearchCategory.All,
    label: SearchCategory.All,
  },
  {
    key: SearchCategory.Equipment,
    label: SearchCategory.Equipment,
  },
  {
    key: SearchCategory.Drawing,
    label: SearchCategory.Drawing,
  },
  {
    key: SearchCategory.PID,
    label: SearchCategory.PID,
  },
  {
    key: SearchCategory.Datasheet,
    label: SearchCategory.Datasheet,
  },
  {
    key: SearchCategory.Other,
    label: SearchCategory.Other,
  },
];

export const FileExtractContentConfigs = {
  [FileExtractContentTypes.table]: {
    color: '#2db7f5',
  },
  [FileExtractContentTypes.equipment]: {
    color: '#87d068',
  },
  [FileExtractContentTypes.attribute]: {
    color: '#FF5733',
  },
  [FileExtractContentTypes.drawing]: {
    color: '#108ee9',
  },
  [FileExtractContentTypes.pipe]: {
    color: '#818cf8',
  },
  [FileExtractContentTypes.piping_connection]: {
    color: '#f50',
  },
  [FileExtractContentTypes.graph]: {
    color: '#a3e635',
  },
  [FileExtractContentTypes.text]: {
    color: '#db2777',
  },
};

export const MarkerOptions = [
  {
    label: 'Equipments',
    value: FileExtractContentTypes.equipment,
    disabled: true,
  },
  {
    label: 'Attributes',
    value: FileExtractContentTypes.attribute,
    disabled: true,
  },
  { label: 'Drawings', value: FileExtractContentTypes.drawing, disabled: true },
  { label: 'Pipe Lines', value: FileExtractContentTypes.pipe, disabled: true },
  { label: 'Tables', value: FileExtractContentTypes.table, disabled: true },
  {
    label: 'Highlight search results',
    value: MarkerType.SEARCH_HIGHLIGHTS,
    disabled: true,
  },
  { label: 'Others', value: FileExtractContentTypes.text, disabled: true },
];

import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Avatar, Drawer, Empty, List, Space, Tag } from 'antd';
import './SearchResultCard.scss';
import { CategoryMeta, SearchCategory } from '../../constants';
import { useNavigate } from 'react-router-dom';
import EquipmentDeatils from '../EquipmentDetails/EquipmentDetails';
import ISDrawer from '../Drawer/Drawer';
import pdf from '../../../assets/images/pdf.png';
import equipment from '../../../assets/images/equipment.png';
import no_result from '../../../assets/images/no_result.jpg';
interface SearchResultCardProps {
  data: any;
  selectedCategory: SearchCategory;
  searcKeyWord: string;
}

const SearchResultCard: React.FC<SearchResultCardProps> = (
  props: SearchResultCardProps
) => {
  const { data, selectedCategory, searcKeyWord } = props;
  const navigate = useNavigate();
  const [isDrawerOpen, changeDrawerOpen] = useState<boolean>(false);
  const [currentSeletedEquipmentId, setCurrentSeletedEquipmentId] =
    useState<string>('');
  const [drawerContent, setDrawerContent] = useState(
    <EquipmentDeatils equipmentId={currentSeletedEquipmentId} />
  );
  const [drawerTitle, setDrawerTitle] = useState<string>();
  return (
    <>
      {data?.length ? (
        <List
          className="w-[60%] cursor-pointer"
          itemLayout="vertical"
          size="large"
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item
              key={item.document.title}
              extra={
                item.document.file_url ? (
                  <div
                    className="rounded-lg shadow-xl cursor-pointer"
                    onClick={(event: any) => {
                      event.stopPropagation();
                    }}
                  >
                    <a href={item.document.file_url} target="_blank">
                      <img
                        width={100}
                        alt="logo"
                        src={item.document.file_url}
                      />
                    </a>
                  </div>
                ) : (
                  <></>
                )
              }
              onClick={() => {
                if (item.document.record_type !== 'equipment') {
                  navigate(
                    `/file/${item.document.record_id}?key=${searcKeyWord}&page_num=${item.document.page_number}`
                  );
                } else {
                  setDrawerTitle(item?.document?.title?.split(' : ')[0]);
                  setCurrentSeletedEquipmentId(item?.document?.record_id);
                  setDrawerContent(
                    <EquipmentDeatils equipmentId={item?.document?.record_id} />
                  );
                  changeDrawerOpen(true);
                }
              }}
            >
              <List.Item.Meta
                avatar={
                  <div className="rounded-lg shadow-xl">
                    {item.document.record_type === 'equipment' && (
                      <img width={100} alt="logo" src={equipment} />
                    )}
                    {item.document.record_type === 'file' && (
                      <img width={100} alt="logo" src={pdf} />
                    )}
                  </div>
                }
                title={
                  <div className="flex flex-row items-start">
                    <a href={item.href} className="flex flex-row">
                      {item.highlight?.title?.snippet ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.highlight?.title?.snippet,
                          }}
                        ></div>
                      ) : (
                        <div>{item.document.title}</div>
                      )}
                    </a>
                    {selectedCategory === SearchCategory.All ? (
                      item.document.record_type === 'equipment' ? (
                        <Tag
                          className="ml-4"
                          color={CategoryMeta.equipment.color}
                        >
                          {CategoryMeta.equipment.name}
                        </Tag>
                      ) : item.document.record_type === 'file' ? (
                        <Tag
                          className="ml-4"
                          color={
                            CategoryMeta[item.document.record_sub_type].color
                          }
                        >
                          {CategoryMeta[item.document.record_sub_type].name}
                        </Tag>
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                }
                description={
                  item.highlight?.content?.snippet ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.highlight?.content?.snippet,
                      }}
                    ></div>
                  ) : (
                    <div>{item.document.content}</div>
                  )
                }
              />
              {item.content}
            </List.Item>
          )}
        />
      ) : (
        <div
          className="flex flex-col items-center justify-center flex-1"
          style={{ height: 'calc(100vh - 141px)', paddingRight: '176px' }}
        >
          <Empty
            image={no_result}
            imageStyle={{ height: 300 }}
            description={<h1>No Result Found</h1>}
          ></Empty>
        </div>
      )}
      <ISDrawer
        title={drawerTitle}
        open={isDrawerOpen}
        children={drawerContent}
        onClose={() => {
          changeDrawerOpen(false);
        }}
        closable={true}
      />
    </>
  );
};

export default SearchResultCard;

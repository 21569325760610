import { useEffect, useState } from 'react';
import {
  Button,
  message,
  Modal,
  Upload,
  UploadProps,
  Select,
  SelectProps,
  Table,
  UploadFile,
  Spin,
} from 'antd';
import {
  ExclamationCircleFilled,
  FileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { CLOUDINARY_UPLOAD_PRESET, CLOUDINARY_URL } from '../../../env';

import {
  deleteFileHelper,
  fileUploadHelper,
  getFiles,
} from '../../utils/api.helper';
import { Files } from '../../interfaces/File';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

const { confirm } = Modal;

const FilesList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<Files[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [selectedType, setSelectedType] = useState();
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [documentType, setDocumentType] = useState<SelectProps['options']>([
    { value: 'p&id', label: 'P&ID' },
    { value: 'drawing', label: 'Drawing' },
    { value: 'datasheet', label: 'Datasheet' },
    { value: 'other', label: 'Other' },
  ]);

  useEffect(() => {
    setFetching(true);
    getFiles().then((files: Files[]) => {
      setData(files);
      setFetching(false);
    });
  }, []);

  const fileUploadProps: UploadProps = {
    name: 'file',
    action: CLOUDINARY_URL,
    data: (file: UploadFile) => ({
      upload_preset: CLOUDINARY_UPLOAD_PRESET,
      // api_key: CLOUDINARY_API_KEY,
      file,
    }),
    beforeUpload: (file) => {
      const isSupported =
        file.type === 'application/pdf' || file.type === 'image/png';
      if (!isSupported) {
        message.error(`${file.name} is not supported`);
      }
      return isSupported;
    },
    onChange(info) {
      const file = info.file;
      if (file.status !== 'uploading') {
        console.log(file, 'uploaded file');
      }
      if (file.status === 'done') {
        message.success(`${file.name} file uploaded successfully`);
        setSelectedFile([{ name: file.name, fileUrl: file.response.url }]);
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  const fileUpload = async () => {
    const fileUploadMeta = {
      title: selectedFile[0].name,
      file_url: selectedFile[0].fileUrl,
      file_type: selectedType,
    };
    fileUploadHelper(fileUploadMeta).then((response: any) => {
      message.success('File uploaded successfully');
      getFiles().then((files: Files[]) => {
        setData(files);
      });
    });
  };

  const handleDocumentUploadModalOk = async () => {
    if (selectedFile) {
      setDocumentUploadModalOpen(false);
      fileUpload();
      setSelectedFile(null);
    } else {
      message.warning('No valid file Selected for upload!');
    }
  };

  const handleDocumentUploadModalCancel = async () => {
    setDocumentUploadModalOpen(false);
    setSelectedFile(null);
  };

  const handleNavigation = (file: any) => {
    navigate(`/file/${file.id}?key=${''}&page_num=${1}`);
  };

  const columns: ColumnsType<Files> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: string, file) => (
        <a
          onClick={() => {
            handleNavigation(file);
          }}
        >
          {title}
        </a>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <>
          {status === 'processing' && (
            <span className="text-orange-500">Processing</span>
          )}
          {status === 'completed' && (
            <span className="text-lime-600">Completed</span>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (value: any, record: Files) => (
        <a
          onClick={() => {
            handelDelete(record);
          }}
        >
          Delete
        </a>
      ),
    },
  ];

  const handelDelete = (record: Files) => {
    showDeleteConfirm(record);
  };

  const showDeleteConfirm = (record: any) => {
    console.log('Record ', record);
    confirm({
      title: 'Are you sure you want to delete this document?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteDocument(record?.id);
      },
      onCancel() {},
    });
  };

  const deleteDocument = async (id: string) => {
    deleteFileHelper(id).then((response) => {
      message.success('Document deleted successfully');
      getFiles().then((files: Files[]) => {
        setData(files);
      });
    });
  };

  return (
    <>
      {!isFetching ? (
        <div className="p-4">
          <Button
            className="mb-4"
            icon={<UploadOutlined />}
            onClick={() => setDocumentUploadModalOpen(true)}
          >
            Upload New File
          </Button>
          <Modal
            open={documentUploadModalOpen}
            title={<p className="text-[18px] font-bold">Upload document</p>}
            onCancel={handleDocumentUploadModalCancel}
            onOk={handleDocumentUploadModalOk}
            cancelButtonProps={{ style: { display: 'none' } }}
            centered
            width={'40%'}
          >
            <>
              <div className="flex-col justify-between w-full mt-5 mb-5">
                <p className="text-[16px] font-semibold mb-1">Select File</p>
                <Upload {...fileUploadProps}>
                  <Button icon={<FileOutlined />}>Select</Button>
                </Upload>
              </div>
              <div className="flex-col justify-between w-full">
                <p className="text-[16px] font-semibold mb-1">File Type</p>
                <Select
                  value={selectedType}
                  style={{ width: '90%' }}
                  placeholder="File Type"
                  onChange={(value: any) => {
                    setSelectedType(value);
                  }}
                  options={documentType}
                />
              </div>
            </>
          </Modal>
          <Table columns={columns} dataSource={data} rowKey={'id'} />
        </div>
      ) : (
        <div
          className="flex items-center justify-center flex-1 p-4"
          style={{ overflow: 'scroll', height: 'calc(100vh - 76px' }}
        >
          <Spin />
        </div>
      )}
    </>
  );
};

export default FilesList;

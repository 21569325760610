import React from 'react';
import { Layout } from 'antd';
import HeaderComponent from '../Header/Header';
import './rightPane.scss';
import dayjs from 'dayjs';

import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../state/hooks';

interface RightPaneProps {}

const { Content } = Layout;

export default function ({}: RightPaneProps) {
  const path = window.location.pathname;
  const isMarginRequired = useAppSelector(
    ({ layout }) => layout.isMarginRequired
  );
  return (
    <Layout className="h-screen">
      {path === '/' ||
      path === '/intelliscan' ||
      path === '/search' ||
      path === '/knowledgenav' ? (
        <></>
      ) : (
        <HeaderComponent />
      )}
      {/* {window.location.pathname !== '/' && <HeaderComponent />} */}
      <Content
        className={`site-layout-background ${
          path === '/' ||
          path === '/intelliscan' ||
          path === '/search' ||
          path === '/knowledgenav' ||
          !isMarginRequired
            ? ''
            : 'm-4'
        }`}
        style={{ minHeight: 360 }}
      >
        {/* <div>
          <span className="font-semibold">
            Date Format Sample(using day.js):{' '}
          </span>
          <span className="mr-2">
            {dayjs(new Date()).format(dateTimeFormat)}
          </span>
          <span>//Check RightPane.tsx</span>
        </div> */}
        <Outlet />
      </Content>
    </Layout>
  );
}

import Typsense, { Client } from 'typesense';
import {
  MultiSearchRequestsSchema,
  MultiSearchResponse,
} from 'typesense/lib/Typesense/MultiSearch';
import { TypesenseSearchContent } from '../interfaces/typesense';

const TYPESENSE_SEARCH_API_KEY = import.meta.env.VITE_TYPESENSE_SEARCH_API_KEY;
const TYPSENSE_COLLECTION = import.meta.env.VITE_TYPSENSE_COLLECTION;
const TYPSENSE_HOST = import.meta.env.VITE_TYPSENSE_HOST;
const TYPSENSE_PORT = import.meta.env.VITE_TYPSENSE_PORT;
const TYPSENSE_PROTOCOL = import.meta.env.VITE_TYPSENSE_PROTOCOL;
const TYPSENSE_CONNECTION_TIMEOUT = import.meta.env
  .VITE_TYPSENSE_CONNECTION_TIMEOUT;
const TYPSENSE_PATH = import.meta.env.VITE_TYPSENSE_PATH;
export class TypesenseSearch {
  private typesenseClient: Client;
  private static typesenseSearchInstance: TypesenseSearch;

  constructor() {
    this.typesenseClient = this.configTypesense();
  }

  static get instance() {
    if (this.typesenseSearchInstance) {
      return this.typesenseSearchInstance;
    } else {
      this.typesenseSearchInstance = new TypesenseSearch();
      return this.typesenseSearchInstance;
    }
  }

  private configTypesense() {
    const config: any = {
      host: TYPSENSE_HOST,
      port: TYPSENSE_PORT,
      protocol: TYPSENSE_PROTOCOL,
      path: TYPSENSE_PATH,
    };
    // if (import.meta.env.PROD) config.path = '/typesense';

    return new Typsense.Client({
      nodes: [config],
      apiKey: TYPESENSE_SEARCH_API_KEY,
      connectionTimeoutSeconds: TYPSENSE_CONNECTION_TIMEOUT,
    });
  }

  public async search(key: string) {
    let searchRequests: MultiSearchRequestsSchema = {
      searches: [
        {
          collection: TYPSENSE_COLLECTION,
          query_by: 'content',
          infix: 'always',
          limit: 100,
          q: `${key}`,
          exclude_fields: 'embedding',
        },
        {
          collection: TYPSENSE_COLLECTION,
          q: `${key}`,
          query_by: 'content',
          infix: 'always',
          filter_by: 'record_type:=equipment',
          limit: 100,
          exclude_fields: 'embedding',
        },
        {
          collection: TYPSENSE_COLLECTION,
          q: `${key}`,
          query_by: 'content',
          infix: 'always',
          filter_by: 'record_type:=file && record_sub_type:=drawing',
          limit: 100,
          exclude_fields: 'embedding',
        },
        {
          collection: TYPSENSE_COLLECTION,
          q: `${key}`,
          query_by: 'content',
          infix: 'always',
          filter_by: 'record_type:=file && record_sub_type:=p&id',
          limit: 100,
          exclude_fields: 'embedding',
        },
        {
          collection: TYPSENSE_COLLECTION,
          q: `${key}`,
          query_by: 'content',
          infix: 'always',
          filter_by: 'record_type:=file && record_sub_type:=datasheet',
          limit: 100,
          exclude_fields: 'embedding',
        },
        {
          collection: TYPSENSE_COLLECTION,
          q: `${key}`,
          query_by: 'content',
          infix: 'always',
          filter_by: 'record_type:=file && record_sub_type:=other',
          limit: 100,
          exclude_fields: 'embedding',
        },
      ],
    };
    const response = await this.typesenseClient.multiSearch.perform(
      searchRequests
    );
    return this.transformSearchResult(response.results);
  }

  public async searchByPage(
    key: string,
    pageNumber: number,
    record_id?: string
  ): Promise<TypesenseSearchContent[]> {
    let searchParameters = {
      q: key,
      query_by: 'title,content',
      filter_by: `page_number:=${pageNumber}&&record_id:=${record_id}`,
      exclude_fields: 'embedding',
      limit: 100,
    };

    return this.typesenseClient
      .collections(TYPSENSE_COLLECTION)
      .documents()
      .search(searchParameters)
      .then(this.transformResult);
  }

  async transformResult(result: any): Promise<TypesenseSearchContent[]> {
    const { hits }: { hits: any[] } = result;
    const documents: TypesenseSearchContent[] = hits?.map(
      (hit) => hit.document
    );
    return new Promise((resolve, reject) => {
      resolve(documents ?? []);
    });
  }

  async transformSearchResult(results: any): Promise<MultiSearchResponse> {
    if (results.length) {
      const finalResult = results.map((result: any) => {
        const finalHits = result.hits.map((hit: any) => {
          if (hit.document.file_url) {
            hit.document.file_url = this.changeFileExtension(
              hit.document.file_url
            );
          }
          return hit;
        });
        result.hits = finalHits;
        return result;
      });
      return new Promise((resolve, reject) => {
        resolve(finalResult);
      });
    }
    return new Promise((resolve, reject) => {
      resolve(results);
    });
  }

  changeFileExtension(url: string, newExtension: string = 'png') {
    let parts = url.split('.');
    if (parts.length > 1) {
      parts[parts.length - 1] = newExtension;
      return parts.join('.');
    } else {
      return url;
    }
  }
}

export default TypesenseSearch.instance;

import { useEffect, useState } from 'react';
import { Collapse, DatePicker } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  Tooltip,
} from 'react-leaflet';
import './mapView.scss';
import dayjs from 'dayjs';
import L, { LatLng } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '../../../hooks/useWindowSize';
import React from 'react';
import MarkerWithBadge from './MarkerWithBadge';
import {
  IBom,
  ICoordinate,
  IDrawing,
  IPartLocation,
} from '../../../interfaces/drawing.interface';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface MapViewProps {
  drawingData: IDrawing;
}

const MapView = ({ drawingData }: MapViewProps) => {
  const [mapHeight, setMapHeight] = useState(500);
  const [width, height] = useWindowSize();
  const [imgWidth, setImgWidth] = useState<number>(0);
  const [imgHeight, setImgHeight] = useState<number>(0);

  useEffect(() => {
    setMapHeight(height - 150);
  }, [height]);

  const getMarkerIcon = (url: string = '') => {
    console.log('calling getMarkerICon');
    return new L.Icon({
      iconUrl:
        'https://res.cloudinary.com/dzsifr04l/image/upload/v1661155536/gallery/HWP_nuyuzg.svg',
      //iconRetinaUrl: url,
      iconSize: new L.Point(30, 30),
    });
  };

  useEffect(() => {
    console.log('Map Data inside mapview :', drawingData);
    if (Object.keys(drawingData).includes('cloudinary_url')) {
      var img = new Image();
      img.onload = function () {
        console.log(img.src, 'Map img url');
        console.log(img.width, 'Map img width');
        console.log(img.height, 'Map img height');
        console.log(imgWidth / 2 - 150, 'centerx');
        setImgWidth(img.width);
        setImgHeight(img.height);
      };
      img.src = drawingData.cloudinary_url;
    }
  }, [drawingData]);

  const calculateCentroid = (coords: Array<ICoordinate>) => {
    const l = coords.length;
    let sum1 = 0;
    let sum2 = 0;

    coords.map((coord: ICoordinate) => {
      sum1 = sum1 + coord.x;
      sum2 = sum2 + coord.y;
    });

    const x = sum1 / l;
    const y = sum2 / l;

    return L.latLng(imgHeight - y, x); // [y,x]
  };

  const displayBOMDetails = (part_number: string) => {
    const data: IBom = drawingData.bom.find(
      (bom: IBom) => bom.part_number === part_number
    )!;
    return (
      <Tooltip>
        <div className="mx-2 my-1">
          <div>
            <span className="font-semibold">UMC : </span>
            <span>{data?.umc}</span>
          </div>
          <div>
            <span className="font-semibold">Part Number : </span>
            <span>{data?.part_number}</span>
          </div>
          <div>
            <span className="font-semibold">Part Name : </span>
            <span>{data?.part_name}</span>
          </div>
          <div>
            <span className="font-semibold">Required Quantity : </span>
            <span>{data?.required_quantity}</span>
          </div>
          <div>
            <span className="font-semibold">Available Quantity : </span>
            <span>{data?.available_quantity}</span>
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div style={{ height: mapHeight }}>
      <MapContainer
        crs={L.CRS.Simple}
        minZoom={-5}
        style={{ height: '100%' }}
        //center={[51.505, -50.0]}
        center={[3000, 2000]}
        // center={[(imgHeight - 596) / 2, (imgWidth - 273.81) / 2]}
        //center={[imgHeight / 2, imgWidth / 2]}
        zoom={-3}
        scrollWheelZoom={true}
      >
        <ImageOverlay
          url={drawingData.cloudinary_url}
          bounds={[
            [0, 0],
            [imgHeight, imgWidth],
          ]}
        />

        {drawingData.part_locations?.map(
          (location: IPartLocation, index: number) => {
            const data = drawingData.bom.find(
              (bom: IBom) => bom.part_number === location.part_number
            )!;
            return (
              // <Marker
              //   icon={getMarkerIcon()}
              //   position={calculateCentroid(location.boundary_region.polygon)}
              //   key={index}
              // >
              //   {displayBOMDetails(location.part_number)}
              // </Marker>

              <MarkerWithBadge
                badgeCount={location.part_number}
                badgeColor={
                  data?.available_quantity > data?.required_quantity
                    ? '#74b9ff' // blue
                    : data?.available_quantity == data?.required_quantity
                    ? '#fdcb6e' // yellow
                    : '#ff7675' // red
                }
                textColor={
                  data?.available_quantity > data?.required_quantity
                    ? '#ffffff' // white
                    : data?.available_quantity == data?.required_quantity
                    ? '#000000' // black
                    : '#ffffff' // white
                }
                position={calculateCentroid(location.boundary_region.polygon)}
                key={index}
              >
                {displayBOMDetails(location.part_number)}
              </MarkerWithBadge>
            );
          }
        )}
      </MapContainer>
    </div>
  );
};
export default MapView;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type chatState = {
  refreshRequired: boolean;
  chatId: string;
};

const initialState = {
  refreshRequired: false,
  chatId: '',
} as chatState;

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setRefreshRequired: (state: chatState, action: PayloadAction<any>) => {
      state.refreshRequired = action.payload;
    },

    // Id of the first chat in the chats array
    setChatId: (state: chatState, action: PayloadAction<any>) => {
      state.chatId = action.payload;
    },
  },
});

export const { setRefreshRequired, setChatId } = chatSlice.actions;
export default chatSlice.reducer;

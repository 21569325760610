import React, { useEffect, useState } from 'react';

interface DynamicTableProps {
  jsonValue: any;
}

const DynamicTable = ({ jsonValue }: DynamicTableProps) => {
  const [tableMatrix, setTableMatrix] = useState<any>();
  const buildTableMatrix = () => {
    let matrix: any[] = [];

    const jsonData = jsonValue.cells;

    jsonData?.forEach((item: any) => {
      // console.log(item);
      const { row_index, column_index, row_span, column_span, content, kind } =
        item;
      for (let i = 0; i < row_index + row_span; i++) {
        if (!matrix[i]) {
          matrix[i] = [];
        }
      }

      // Insert the cell into the correct position in the matrix, with placeholders for spans
      for (let i = row_index; i < row_index + row_span; i++) {
        for (let j = column_index; j < column_index + column_span; j++) {
          if (i === row_index && j === column_index) {
            matrix[i][j] = { content, row_span, column_span, kind }; // First cell contains the content
          } else {
            matrix[i][j] = { isPlaceholder: true }; // Placeholder cells for spans
          }
        }
      }
    });

    return matrix;
  };

  useEffect(() => {
    const tMatrix = buildTableMatrix();
    setTableMatrix(tMatrix);
    // console.log(tMatrix);
  }, []);

  return (
    <table className="border-collapse">
      <tbody>
        {tableMatrix?.map((row: any, row_index: any) => (
          <tr key={row_index}>
            {row.map(
              (cell: any, cell_index: any) =>
                !cell.isPlaceholder ? (
                  <td
                    key={cell_index}
                    rowSpan={cell.row_span || 1}
                    colSpan={cell.column_span || 1}
                    className={`border-neutral-200 px-4 py-2 border-solid ${
                      cell.kind === 'columnHeader'
                        ? 'bg-neutral-100 font-black'
                        : ''
                    }`}
                  >
                    {cell.content === '' ? (
                      <p style={{ visibility: 'hidden' }}>' '</p>
                    ) : (
                      cell.content
                    )}
                  </td>
                ) : null // Don't render placeholder cells
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Tooltip,
  Upload,
  UploadProps,
  message,
} from 'antd';
import Icon, {
  CarryOutOutlined,
  CheckOutlined,
  CheckSquareOutlined,
  CompassOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DropboxOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FileOutlined,
  FilePdfOutlined,
  ForkOutlined,
  HomeOutlined,
  PlusOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  UserOutlined,
  WechatOutlined,
} from '@ant-design/icons';
import './leftPane.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useMatchedRoute from '../../hooks/useMatchedRoute';
import axios from 'axios';
import { BASE_URL } from '../../env';
import { IChats } from '../../interfaces/chats.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  setChatId,
  setRefreshRequired,
} from '../../state/reducers/chatReducer';
import { setDrawingsRefreshRequired } from '../../state/reducers/drawingReducer';
import { toggeleSidepanel } from '../../state/reducers/layout.reducer';

const { Sider } = Layout;

interface LeftPaneProps {}

const LeftPane = (props: LeftPaneProps) => {
  let navigate = useNavigate();
  const route = useMatchedRoute();
  const [openKeys, setOpenKeys] = useState<any>([]);
  const [collapsed, setCollapsed] = useState(false);

  const [subItems, setSubItems] = useState<any>();
  const [drawingSubItems, setDrawingSubItems] = useState<any>();
  const sidePanelCollapsed = useAppSelector(
    ({ layout }) => layout.sidePanelCollapsed
  );
  type MenuItem = Required<MenuProps>['items'][number];

  const [defaultOpenKeys, setDefaultOpenKeys] = useState<Array<string>>([]);

  const [selectedKey, setSelectedKey] = useState('');

  const [isDeleting, setIsDeleting] = useState(false);

  const dispatch = useAppDispatch();

  const refreshReq = useAppSelector(({ chat }) => chat.refreshRequired);
  const drawingsRefreshReq = useAppSelector(
    ({ drawing }) => drawing.refreshRequired
  );

  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
    } as MenuItem;
  };
  const params = useParams();
  const location = useLocation();

  const [items, setItems] = useState<MenuItem[]>([
    getItem(
      'My Queries',
      '/queries',
      <WechatOutlined style={{ fontSize: '20px' }} />,
      []
    ),
    getItem('Drawings', '/drawings', <EditOutlined />, []),
    getItem('Jobs', '/jobs', <ExclamationCircleFilled />),
    getItem('Actions', '/actions', <ThunderboltOutlined />),
    getItem('SAP Work Orders', '/sapworkorders', <DatabaseOutlined />),
    getItem('Permits', '/permits', <CarryOutOutlined />),
    getItem('Isolations', '/isolations', <ForkOutlined />),
    getItem('Inspections', '/inspections', <CheckSquareOutlined />),
    getItem('Work Procedures', '/workprocedures', <DatabaseOutlined />),
    getItem('Operator Rounds', '/operatorrounds', <CompassOutlined />),
    getItem('Users', '/users', <UserOutlined />),
    getItem('Documents', '/documents', <FileOutlined />),
    getItem('Equipments', '/equipments', <DropboxOutlined />),
    getItem('Files', '/files', <FilePdfOutlined />),
    getItem('Intelliscan', '/intelliscan', <HomeOutlined />),
    getItem('Knowledge Nav', '/knowledgenav', <ForkOutlined />),
  ]);

  const { confirm } = Modal;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // useEffect(() => {
  //   if (route?.parent_key !== undefined) {
  //     setOpenKeys([route.parent_key.toString()]);
  //   }
  //   console.log(route, 'route changed');
  // }, [route]);

  useEffect(() => {
    // console.log(location, 'location changed');
    let arr = [location.pathname.toString()];
    // adding the parent keys in case of sub menu items
    if (location.pathname.includes('chats/')) {
      arr.push('/queries');
    } else if (location.pathname.includes('drawings/')) {
      arr.push('/drawings');
    }
    setOpenKeys(arr);
  }, [location]);

  useEffect(() => {
    // console.log(refreshReq, 'refresh required');
    if (refreshReq) {
      getChatThreads();
    }
  }, [refreshReq]);

  useEffect(() => {
    if (drawingsRefreshReq) {
      getDrawings();
    }
  }, [drawingsRefreshReq]);

  const showDeleteConfirm = (id: string | undefined, item: string) => {
    confirm({
      title: `Are you sure you want to delete this ${item}?`,
      icon: <ExclamationCircleFilled />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setDeleteModalOpen(false);
        if (item === 'chat') {
          deleteChat(id);
        } else if (item === 'drawing') {
          deleteDrawing(id);
        }
      },
      onCancel() {
        setDeleteModalOpen(false);
      },
    });
  };

  useEffect(() => {
    // console.log(params.id, 'params id');
    // adding delete icon to the selected submenu item(chat)
    if (
      params.id !== undefined &&
      location.pathname.includes('chats') &&
      subItems?.length
    ) {
      let tmpChats: any = [];
      subItems?.map((subItem: any) => {
        if (subItem?.key === '/chats/' + params.id) {
          tmpChats.push(
            getItem(
              <div className="flex w-[full] justify-between items-center">
                <span className="truncate max-w-[150px]" title={subItem.label}>
                  {subItem.label}
                </span>
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log('delete clicked');
                    //setIsDeleting(true);
                    //deleteChat(params?.id);
                    showDeleteConfirm(params?.id, 'chat');
                  }}
                />
              </div>,
              `/chats/${params?.id}`,
              ''
            )
          );
        } else {
          tmpChats.push(subItem);
        }
      });
      updateMenuItem('/queries', tmpChats);
      updateMenuItem('/drawings', drawingSubItems);
    } else if (
      params.id !== undefined &&
      location.pathname.includes('drawings') &&
      drawingSubItems?.length
    ) {
      let tmp: any = [];
      drawingSubItems?.map((subItem: any) => {
        if (subItem?.key === '/drawings/' + params.id) {
          tmp.push(
            getItem(
              <div className="flex w-[full] justify-between items-center">
                <span className="truncate max-w-[150px]" title={subItem.label}>
                  {subItem.label}
                </span>
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    console.log('delete clicked');
                    //setIsDeleting(true);
                    //deleteChat(params?.id);
                    showDeleteConfirm(params?.id, 'drawing');
                  }}
                />
              </div>,
              `/drawings/${params?.id}`,
              ''
            )
          );
        } else {
          tmp.push(subItem);
        }
      });
      updateMenuItem('/drawings', tmp);
      updateMenuItem('/queries', subItems);
    }
    //  else if (subItems?.length > 0) {
    //   updateMenuItem('/queries', subItems);
    // } else if (drawingSubItems?.length > 0) {
    //   updateMenuItem('/drawings', drawingSubItems);
    // }
    else {
      updateMenuItem('/queries', subItems);
      updateMenuItem('/drawings', drawingSubItems);
    }
  }, [params, subItems, drawingSubItems]);

  useEffect(() => {
    // console.log(items, 'menu item list');
  }, [items]);

  useEffect(() => {}, []);

  const onOpenChange = (_openKeys: any) => {
    setOpenKeys([..._openKeys]);
    console.log(_openKeys, 'open keys changed');
  };

  const updateMenuItem = (parentkey: string, subitems: any) => {
    setItems((prevState) => {
      const arr = [...prevState];
      let updtArr = arr.map((item) => {
        if (item?.key === parentkey) {
          return { ...item, children: subitems };
        } else {
          //console.log(item, 'menu item');
          return item;
        }
      });
      return updtArr;
    });
  };

  const deleteChat = async (id: string | undefined) => {
    let url = BASE_URL + '/chats/' + id;
    setIsDeleting(true);
    message.loading({
      content: 'Deleting chat...',
      duration: 0,
      key: 'deleting',
    });
    await axios
      .delete(url)
      .then((res) => {
        console.log(res.data, 'delete response');
        setIsDeleting(false);
        message.destroy('deleting');
        navigate('/chats/newchat');
        getChatThreads();
      })
      .catch((err) => {
        console.log(err.message);
        message.destroy('deleting');
        message.error(err.message);
        setIsDeleting(false);
      });
  };

  const deleteDrawing = async (id: string | undefined) => {
    let url = BASE_URL + '/drawings/' + id;
    setIsDeleting(true);
    message.loading({
      content: 'Deleting drawing...',
      duration: 0,
      key: 'deleting',
    });
    await axios
      .delete(url)
      .then((res) => {
        console.log(res.data, 'delete drawing response');
        setIsDeleting(false);
        message.destroy('deleting');
        navigate('/drawings');
        getDrawings();
      })
      .catch((err) => {
        console.log(err.message);
        message.destroy('deleting');
        message.error(err.message);
        setIsDeleting(false);
      });
  };

  const getChatThreads = async () => {
    let url = BASE_URL + '/chats';
    await axios
      .request({
        method: 'get',
        url,
      })
      .then((response) => {
        console.log(response.data, 'chats');
        let tempChats: any = [];
        let tmpOpenKeys: any = [];
        response.data.map((data: IChats) => {
          if (tempChats.length < 20) {
            tempChats.push(
              getItem(
                <div className="flex w-[full] justify-between items-center">
                  <span className="truncate max-w-[150px]" title={data.title}>
                    {data.title}
                  </span>
                </div>,
                `/chats/${data.id}`,
                ''
              )
            );
            tmpOpenKeys.push(`/chats/${data.id}`);
          }
        });
        //setDefaultOpenKeys(tmpOpenKeys);
        if (tempChats.length > 0) {
          setSubItems(tempChats);
          const parts = tempChats[0].key.split('/');
          dispatch(setChatId(parts[parts.length - 1]));
          console.log(parts[parts.length - 1], 'initial chat id');
          updateMenuItem('/queries', tempChats);
          if (refreshReq) {
            dispatch(setRefreshRequired(false));
          }
        }
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
      });
  };

  const getDrawings = async () => {
    let url = BASE_URL + '/drawings';
    await axios
      .request({
        method: 'get',
        url,
      })
      .then((response) => {
        console.log(response.data, 'drawings');
        let tempDrawings: any = [];

        response.data.map((data: any) => {
          //if (tempDrawings.length < 20) {
          tempDrawings.push(
            getItem(
              <div className="flex w-[full] justify-between items-center">
                <span className="truncate max-w-[150px]" title={data.title}>
                  {data.title}
                </span>
              </div>,
              `/drawings/${data.id}`,
              ''
            )
          );

          //}
        });
        if (tempDrawings.length > 0) {
          setDrawingSubItems(tempDrawings);
          updateMenuItem('/drawings', tempDrawings);
          if (drawingsRefreshReq) {
            dispatch(setDrawingsRefreshRequired(false));
          }
        }
      })
      .catch((e) => {
        console.log(e);
        message.error(e.message);
      });
  };

  useEffect(() => {
    getChatThreads();
    getDrawings();
  }, []);

  return (
    <Sider
      className="h-screen overflow-y-auto w-[30vw]"
      width={250}
      collapsible
      collapsed={sidePanelCollapsed}
      onCollapse={(value) => {
        setCollapsed(value);
        dispatch(toggeleSidepanel(value));
      }}
    >
      <div
        className="h-14 flex items-center justify-center cursor-pointer"
        onClick={() => {
          navigate('/');
        }}
      >
        <img
          src={
            sidePanelCollapsed
              ? 'https://res.cloudinary.com/dzsifr04l/image/upload/v1667547471/gallery/maximl_logo_white_72ppi_tgr5t8.png'
              : 'https://res.cloudinary.com/dzsifr04l/image/upload/v1667546876/gallery/maximl_logo_with_text_white_36ppi_lghcvs.png'
          }
          className="object-contain w-4/5 h-6"
          alt="Maximl Logo"
        />
      </div>
      <div className="flex justify-center w-full my-4">
        <Button
          // type="primary"
          // ghost
          icon={<PlusOutlined />}
          size={'large'}
          onClick={() => navigate('/chats/newchat')}
        >
          {!sidePanelCollapsed && 'New Query'}
        </Button>
      </div>
      <div className="h-full">
        <Menu
          selectedKeys={[location.pathname]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="dark"
          mode="inline"
          items={items}
          onClick={(obj) => {
            if (
              obj.key.includes('documents') ||
              obj.key.includes('chats') ||
              obj.key.includes('drawings') ||
              obj.key.includes('files') ||
              obj.key.includes('intelliscan') ||
              obj.key.includes('equipments') ||
              obj.key.includes('knowledgenav')
            ) {
              //setSelectedKey(obj.key);
              navigate(obj.key);
              console.log(obj.key, 'route path');
            }
          }}
        />
      </div>
    </Sider>
  );
};

export default LeftPane;

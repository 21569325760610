import { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import { getEquipments } from '../../utils/api.helper';
import { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';
import { Equipment } from '../../interfaces/Equipment';
import ISDrawer from '../Drawer/Drawer';
import EquipmentDeatils from '../EquipmentDetails/EquipmentDetails';

const EquipmentList = () => {
  const navigate = useNavigate();
  const [isFetching, setFetching] = useState<boolean>(true);
  const [data, setData] = useState<Equipment[]>([]);
  const [isDrawerOpen, changeDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState(
    <EquipmentDeatils equipmentId={''} />
  );
  const [drawerTitle, setDrawerTitle] = useState<string>();

  useEffect(() => {
    setFetching(true);
    getEquipments().then((equipments: Equipment[]) => {
      setData(equipments);
      setFetching(false);
    });
  }, []);

  const handleRow = (equipment: Equipment) => {
    setDrawerTitle(equipment.equipment_tag);
    setDrawerContent(<EquipmentDeatils equipmentId={equipment.id} />);
    changeDrawerOpen(true);
  };

  const columns: ColumnsType<Equipment> = [
    {
      title: 'Equipment Tag',
      dataIndex: 'equipment_tag',
      key: 'equipment_tag',
      render: (equipment_tag: string, equipment: Equipment) => (
        <a
          onClick={() => {
            handleRow(equipment);
          }}
        >
          {equipment_tag}
        </a>
      ),
    },
    {
      title: 'Equipment Description',
      dataIndex: 'equipment_description',
      key: 'equipment_description',
      render: (equipment_description: string) => <>{equipment_description}</>,
    },
  ];

  return (
    <div
      className="flex flex-1 p-4"
      style={{ overflow: 'scroll', height: 'calc(100vh - 76px' }}
    >
      {!isFetching ? (
        <Table
          className="w-[100%]"
          columns={columns}
          dataSource={data}
          rowKey={'id'}
        />
      ) : (
        <div className="h-[100%] w-[100%] flex items-center justify-center">
          <Spin />
        </div>
      )}
      <ISDrawer
        title={drawerTitle}
        open={isDrawerOpen}
        children={drawerContent}
        onClose={() => {
          changeDrawerOpen(false);
        }}
        closable={true}
      />
    </div>
  );
};

export default EquipmentList;

import { Popover } from 'antd';
import { useState, useRef } from 'react';

export const TruncatedCell = ({ text }: any) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const contentRef = useRef(null);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  const handleMouseEnter = () => {
    const contentElement: any = contentRef.current;

    const isOverflow = contentElement.scrollWidth > contentElement.clientWidth;
    setPopoverVisible(isOverflow);
  };

  const handleMouseLeave = () => {
    setPopoverVisible(false);
  };

  return (
    <Popover
      content={<div className="text-[14px] font-semibold max-w-md">{text}</div>}
      open={popoverVisible}
      //onOpenChange={handlePopoverVisibleChange}
    >
      <div
        ref={contentRef}
        style={{
          width: '100px',
          height: '20px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {text}
      </div>
    </Popover>
  );
};

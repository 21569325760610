import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type drawingState = {
  refreshRequired: boolean;
};

const initialState = {
  refreshRequired: false,
} as drawingState;

const drawingSlice = createSlice({
  name: 'drawing',
  initialState,
  reducers: {
    setDrawingsRefreshRequired: (
      state: drawingState,
      action: PayloadAction<any>
    ) => {
      state.refreshRequired = action.payload;
    },
  },
});

export const { setDrawingsRefreshRequired } = drawingSlice.actions;
export default drawingSlice.reducer;

import { SendOutlined } from '@ant-design/icons';
import { message, Input } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GlobalSearch.scss';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import Profile from '../../components/Profile/Profile';

const GlobalSearch = () => {
  const { Search } = Input;
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [isAsking, setIsAsking] = useState(false);

  return (
    <div className="flex flex-col items-center w-[100vw] h-[100vh]">
      <div className="flex items-center justify-between w-full py-4 pl-4 pr-5">
        <img
          src="https://maximl.com/wp-content/uploads/2020/10/MaximlLOGO.png"
          alt="maximl-logo"
          className="h-4"
        />
        <Profile />
      </div>
      <div className="flex h-[100vh] w-full justify-center items-center bg-[url('https://res.cloudinary.com/dzsifr04l/image/upload/v1686163401/athena_background_utzu3a.gif')] bg-no-repeat bg-contain bg-[#000000] bg-center">
        <div className="w-[60%] p-1 bg-white rounded-xl">
          <Search
            size="large"
            placeholder="Search"
            enterButton={question !== '' && !isAsking && <SendOutlined />}
            // enterButton={question === '' || isAsking ? null : <SendOutlined />}
            readOnly={isAsking}
            onSearch={(e) => {
              if (question !== '' && !isAsking) {
                navigate(`/search?key=${question}`); // navigating to the newly created drawing details page
              } else if (isAsking) {
                message.warning('question already asked!');
              } else {
                message.warning('question should not be empty!');
              }
            }}
            onChange={(e: any) => setQuestion(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalSearch;

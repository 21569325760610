import React, { FC } from 'react';
interface KeyValueDisplayProps {
  nodeData: any;
}

const KeyValueDisplay: FC<KeyValueDisplayProps> = ({ nodeData }) => {
  // Check if data is provided
  if (!nodeData || !Object.entries(nodeData).length) {
    return <div>No data available</div>;
  }

  return (
    <div className="flex flex-col gap-3">
      {Object.entries(nodeData).map(([key, value]) => (
        <div
          className="bg-neutral-100 flex flex-row items-center justify-between px-2 py-2 text-lg rounded-md"
          key={key}
        >
          <strong>{key}</strong> <>{value ?? ''}</>
        </div>
      ))}
    </div>
  );
};

export default KeyValueDisplay;

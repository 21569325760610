import { Modal, Popover, Spin, Table, message } from 'antd';
import axios from 'axios';
import Papa from 'papaparse';
import React, { useEffect, useRef, useState } from 'react';
import { TruncatedCell } from './TruncatedCell';

interface ModalProps {
  visible: boolean;
  setVisible: any;
  loading: boolean;
  setLoading: any;
  title: string;
  isPdf: boolean;
  presignedUrl: string;
}

const DocumentViewModal = ({
  visible,
  setVisible,
  loading,
  setLoading,
  title,
  isPdf,
  presignedUrl,
}: ModalProps) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [data, setData] = useState<Array<any>>([]);
  const [columns, setColumns] = useState<Array<any>>([]);
  const fetchPdfFile = async (presigned_url: string) => {
    message.loading({
      content: 'Fetching file...',
      duration: 0,
      key: 'downloading',
    });
    await axios
      .get(presigned_url, { responseType: 'blob' })
      .then((res) => {
        message.destroy('downloading');
        console.log('Download resp', res);
        const newblob = new Blob([res.data], {
          type: 'application/pdf',
        });
        const srcUrl = URL.createObjectURL(newblob);
        setPdfUrl(srcUrl);
        setLoading(false);
      })
      .catch((err) => {
        message.destroy('downloading');
        message.error(err.message);
        setLoading(false);
      });
  };

  const fetchCsvFile = async (presigned_url: string) => {
    await axios
      .get(presigned_url)
      .then((res) => {
        const parsedData = Papa.parse(res.data, {
          header: true,
          skipEmptyLines: true,
        });

        const dataSource = parsedData.data;
        const columns = parsedData.meta.fields!.map((field: string) => ({
          title: <TruncatedCell text={field} />,
          dataIndex: field,
          key: field,
          render: (text: any) => <TruncatedCell text={text} />,
        }));
        setData(dataSource);
        setColumns(columns);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching or parsing CSV file:', err);
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loading) {
      if (isPdf) {
        fetchPdfFile(presignedUrl);
      } else {
        fetchCsvFile(presignedUrl);
      }
    }
  }, [loading]);

  const handleFileViewModalClose = () => {
    setVisible(false);
    setPdfUrl('');
    setData([]);
  };

  return (
    <Modal
      visible={visible}
      title={title}
      footer={null}
      //closable={false}
      onCancel={handleFileViewModalClose}
      centered
      width={'80%'}
      bodyStyle={{
        height: '70%',
      }}
    >
      {loading ? (
        <div className="h-[600px] flex flex-col items-center justify-center">
          <Spin spinning={loading} className="mb-2"></Spin>
          <h3>Please wait while the file is being fetched...</h3>
        </div>
      ) : pdfUrl !== '' ? (
        <div className="">
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '600px' }}
          ></iframe>
        </div>
      ) : (
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          bordered={true}
          pagination={{ position: ['bottomCenter'] }}
          scroll={{ x: true, y: 450 }}
        />
      )}
    </Modal>
  );
};

export default DocumentViewModal;

import { SendOutlined } from '@ant-design/icons';
import { message, Input, Avatar } from 'antd';
import {
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchResults.scss';
import useQuery from '../../hooks/useQuery';
import { Tabs } from 'antd';
import SearchResultCard from '../../components/SearchResultCard/SearchResultCard';
import typesenseSearch from '../../utils/searchHelper';
import Profile from '../../components/Profile/Profile';
import { SearchCategory, searchCategories } from '../../constants';
import { MultiSearchResponse } from 'typesense/lib/Typesense/MultiSearch';
import debounce from 'lodash-es/debounce';

const SearchResults = () => {
  const { Search } = Input;
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<MultiSearchResponse<[]>>();
  const [catData, setCatData] = useState<any[]>();
  const [seletedCatTab, setSeletedCatTab] = useState<SearchCategory>(
    SearchCategory.All
  );
  const queryParams = useQuery();
  useEffect(() => {
    const key = queryParams.get('key');
    if (key) {
      setSearchKey((previousSate) => key);
      onSearch(key);
    }
  }, []);

  const onSearch = (keyWord?: string) => {
    const key = keyWord ? keyWord : searchKey;
    if (key && key.length && key.length > 4 && !isSearching) {
      setIsSearching(true);
      typesenseSearch.search(key).then((data: MultiSearchResponse<[]>) => {
        setIsSearching(false);
        console.log('JSON Result Of type sense ', data);
        data ? setSearchResult(data) : null;
      });
    }
  };

  const handleSearchKeyChange = (event: any) => {
    const { value } = event.target;
    setSearchKey(value);
    debounceSearch(value);
  };
  const debounceSearch = useCallback(debounce(onSearch, 300), []);

  const onCategoryChange = (key: string) => {
    setSeletedCatTab(key as SearchCategory);
  };

  useEffect(() => {
    setCategoryData();
  }, [seletedCatTab]);
  useEffect(() => {
    setCategoryData();
  }, [searchResult]);

  const setCategoryData = () => {
    const cloneResult: any = searchResult ?? [];
    switch (seletedCatTab) {
      case SearchCategory.All:
        setCatData(cloneResult[0]?.hits);
        break;
      case SearchCategory.Equipment:
        setCatData(cloneResult[1]?.hits);
        break;
      case SearchCategory.Drawing:
        setCatData(cloneResult[2]?.hits);
        break;
      case SearchCategory.PID:
        setCatData(cloneResult[3]?.hits);
        break;
      case SearchCategory.Datasheet:
        setCatData(cloneResult[4]?.hits);
        break;
      case SearchCategory.Other:
        setCatData(cloneResult[5]?.hits);
        break;
    }
  };

  return (
    <div className="flex flex-col w-[100vw] h-[100vh]">
      <div className="flex items-center justify-between w-full py-4 pl-4 pr-5">
        <div className="flex items-center w-full">
          <img
            src="https://maximl.com/wp-content/uploads/2020/10/MaximlLOGO.png"
            alt="maximl-logo"
            className="h-4"
          />
          <div className="w-[60%] p-1 bg-white rounded-xl pl-8">
            <Search
              className="iis-search-input"
              size="middle"
              placeholder="Search"
              value={searchKey}
              // enterButton={searchKey !== '' && !isSearching && <SendOutlined />}
              readOnly={isSearching}
              onSearch={(e) => {
                if (searchKey !== '' && !isSearching) {
                  onSearch();
                } else if (isSearching) {
                  message.warning(
                    'Sorry, your previous search is not yet completed'
                  );
                }
              }}
              onChange={handleSearchKeyChange}
              // onChange={handleSearchKeyChange}
            />
          </div>
        </div>
        <Profile />
      </div>
      <div className="flex flex-row">
        <div className="w-44 false-tab"></div>
        <Tabs
          className="flex-1"
          defaultActiveKey="1"
          items={searchCategories}
          onChange={onCategoryChange}
        />
      </div>
      <div className="pl-44 flex-col flex-1 overflow-scroll">
        {!isSearching && (
          <SearchResultCard
            data={catData}
            selectedCategory={seletedCatTab}
            searcKeyWord={searchKey}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResults;

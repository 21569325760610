import Profile from '../../../intelliscan/components/Profile/Profile';
import GraphContainer from '../../components/GraphContainer';
import './KnowledgeHome.scss';
import { FC, useCallback, useEffect, useState } from 'react';
import { debounce, set } from 'lodash-es';
import { getGraphData } from '../../utils/apiHelpers';
import { mockGraphData } from '../../utils/constants';
import loaderSVG from '../../../assets/images/Gear-0.2s-200px.svg';
import { makeGraph } from '../../utils/GraphHelper';
import Graph from 'graphology';
import { Drawer, Input } from 'antd';
import { SigmaEdgeEventPayload, SigmaNodeEventPayload } from 'sigma/sigma';
import { useSigma } from '@react-sigma/core';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import emptyState from '../../../assets/images/no_result.jpg';
import { useSearchParams } from 'react-router-dom';

const KnowledgeGraph: React.FC = () => {
  const { Search } = Input;
  const [question, setQuestion] = useState<string>();
  const [isSearching, setIsSearching] = useState<boolean>();
  const [isFirstHit, setIsFirstHit] = useState<boolean>(true);
  const [isInitialLanding, setIsInitialLanding] = useState<boolean>(true);
  const [graphData, setGraphData] = useState<any>(null);
  const [drawerOpenState, setDrawerOpenState] = useState<boolean>(false);
  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [drawerChild, setDrawerChild] = useState<any>(
    <KeyValueDisplay nodeData={{}} />
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const onSearch = (searchKeyword: string) => {
    setIsInitialLanding(false);
    if (
      searchKeyword &&
      searchKeyword.length &&
      searchKeyword.length > 2 &&
      !isSearching
    ) {
      const questionBody = {
        question: searchKeyword,
      };
      setIsSearching(true);
      // Set a query parameter
      setSearchParams({ search: searchKeyword });

      getGraphData(questionBody)
        .then((res) => {
          setIsSearching(false);
          setGraphData(res);
          setIsFirstHit(false);
        })
        .catch(() => {
          setIsSearching(false);
          setGraphData(null);
          setIsFirstHit(false);
          console.log('Error: While fetching Graph');
        });
    }
  };
  const debounceSearch = useCallback(debounce(onSearch, 300), []);
  const handleQuestionChange = (event: any) => {
    const { value } = event.target;
    setQuestion(value);
  };
  const onNodeClick = (event: SigmaNodeEventPayload, nodeDisplayData: any) => {
    setDrawerTitle('Node Details');
    setDrawerChild(<KeyValueDisplay nodeData={nodeDisplayData} />);
    setDrawerOpenState(true);
    console.log('Knowdledge graph node click handler ', event, nodeDisplayData);
  };
  const onEdgeClick = (event: SigmaEdgeEventPayload) => {
    setDrawerTitle('Edge Details');
    // setDrawerOpenState(true);
    console.log('Knowdledge graph edge click handler ', event);
  };

  useEffect(() => {
    const searchParam: string = searchParams.get('search') ?? '';
    setQuestion(searchParam);
    if (searchParam) {
      onSearch(searchParam);
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col w-[100vw] h-[100vh]">
      <div className="box_shadow flex items-center justify-between w-full py-4 pl-4 pr-5">
        <div className="flex items-center">
          <img
            src="https://maximl.com/wp-content/uploads/2020/10/MaximlLOGO.png"
            alt="maximl-logo"
            className="h-4"
          />
        </div>
        {!isInitialLanding && (
          <div className="w-[60%] p-1 bg-white rounded-xl pl-8">
            <Search
              placeholder="Ask a question, e.g., 'Which is the flow control valve attached to the process inlet line of column DA33401?'"
              // style={{ borderRadius: '40px' }}
              onChange={handleQuestionChange}
              onSearch={onSearch}
              enterButton
              value={question}
            />
          </div>
        )}
        <Profile />
      </div>
      {isSearching && (
        <div className="flex flex-col items-center justify-center flex-1">
          <img src={loaderSVG} alt="loading" />
          <p className="text-xl text-black">
            Drilling into the vast reservoir of information,
          </p>
          <p className="text-xl text-black">
            Your query is transforming into an insightful knowledge graph.
          </p>
        </div>
      )}
      {!isSearching && question && graphData && (
        <div className="flex flex-1 mt-2">
          <GraphContainer
            graphData={graphData}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
          />
        </div>
      )}

      {!isSearching &&
        question &&
        !isFirstHit &&
        !graphData &&
        !graphData?.nodes?.length && (
          <div className="flex flex-col items-center justify-center flex-1">
            <img height={400} src={emptyState} alt={'Not_found'} />
            <p className="text-xl text-black">No data found for this query</p>
          </div>
        )}
      {isInitialLanding && (
        <div className="flex flex-1 w-full justify-center items-center bg-[url('https://res.cloudinary.com/dzsifr04l/image/upload/v1686163401/athena_background_utzu3a.gif')] bg-no-repeat bg-contain bg-[#000000] bg-center">
          <div className="w-[60%] p-1 bg-white rounded-xl">
            <Search
              placeholder="Ask a question, e.g., 'Which is the flow control valve attached to the process inlet line of column DA33401?'"
              // style={{ borderRadius: '40px' }}
              onChange={handleQuestionChange}
              onSearch={onSearch}
              enterButton
              value={question}
            />
          </div>
        </div>
      )}
      <Drawer
        className=".is-drawer"
        title={drawerTitle}
        placement={'right'}
        onClose={() => {
          setDrawerOpenState(false);
        }}
        open={drawerOpenState}
        width={'30%'}
        children={drawerChild}
        destroyOnClose={true}
        closable={true}
        mask={true}
      />
    </div>
  );
};

export default KnowledgeGraph;

import React from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import './style.scss';

interface ImageSourcesProps {
  sources: Array<string>;
}

const MessageImageSources = React.memo(({ sources }: ImageSourcesProps) => {
  const getImageName = (url: string) => {
    const parts = url.split('/');
    const imageNameWithExtension = parts[parts.length - 1];
    const imageName = imageNameWithExtension.split('.')[0];
    return imageName;
  };

  const getThumbnailUrl = (url: string) => {
    const thumbnailParams = '/c_thumb,q_70,h_100,w_100/';
    const versionIndex = url.indexOf('/v');
    const modifiedUrl =
      url.slice(0, versionIndex) +
      thumbnailParams +
      url.slice(versionIndex + 1);
    return modifiedUrl;
  };

  // const onInit = () => {
  //   console.log('lightGallery has been initialized');
  // };

  return (
    <div className="flex-col mt-2 mb-2">
      <p className="text-[16px] font-semibold underline underline-offset-2">
        Related Images
      </p>

      <div className="flex max-w-[80%] overflow-x-auto">
        <LightGallery
          //onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
          elementClassNames="custom-class-name"
          download={true}
          hideControlOnEnd={true}
        >
          {sources.map((src: string, index) => {
            return (
              <div key={index} className="gallery-item" data-src={src}>
                <img
                  className="img-responsive object-cover object-top w-full h-full"
                  //style={{ marginRight: 3 }}
                  alt={getImageName(src)}
                  src={getThumbnailUrl(src)}
                />
              </div>
            );
          })}
        </LightGallery>
      </div>
    </div>
  );
});

export default MessageImageSources;

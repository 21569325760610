import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LauoutState = {
  fullscreen: boolean;
  sidePanelCollapsed: boolean;
  isMarginRequired: boolean;
};

const initialState: LauoutState = {
  fullscreen: false,
  sidePanelCollapsed: true,
  isMarginRequired: true,
} as LauoutState;

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggeleSidepanel: (state: LauoutState, action: PayloadAction<boolean>) => {
      state.sidePanelCollapsed = action.payload;
    },
    changeMainMargin: (state: LauoutState, action: PayloadAction<boolean>) => {
      state.isMarginRequired = action.payload;
    },
  },
});

export const { toggeleSidepanel, changeMainMargin } = layoutSlice.actions;
export default layoutSlice.reducer;

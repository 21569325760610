import React, { useEffect, useRef, useState } from 'react';
import MaximlTable from '../../components/MaximlTable/Table';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetDocumentsQuery } from '../../state/reducers/apiReducer/slices/paginationSlice';
import {
  PaginationProps,
  Spin,
  Empty,
  Pagination,
  Button,
  message,
  Modal,
  Upload,
  UploadProps,
  Dropdown,
  Menu,
  MenuProps,
  Select,
  SelectProps,
  Tag,
  Table,
  Popover,
  Tooltip,
} from 'antd';
// import { Columns } from './Columns';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { StringColumnFilter } from '../../components/MaximlTable/Filters/StringColumnFilter';
import { dateTimeFormat } from '../../settings/settings';
import axios from 'axios';
import { IDocument } from '../../interfaces/documents.interface';
import {
  ExclamationCircleFilled,
  FileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { BASE_URL } from '../../env';
import Papa from 'papaparse';
import CSVTableView from './Components/CsvTableView';
import DocumentViewModal from './Components/DocumentViewModal';
import { DropdownColumnFilter } from '../../components/MaximlTable/Filters/DropdownColumnFilter';

const { confirm } = Modal;

const Documents = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isChanged, setIsChanged] = useState(false);
  const [map, setMap] = useState<{ [index: number]: boolean }>({});
  const [menuVisible, setMenuVisible] = useState<boolean>();
  const [pageX, setPageX] = useState<number>();
  const [pageY, setPageY] = useState<number>();
  const [seletedDocumentRow, setSelectedDocumentRow] = useState<IDocument>();
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<SelectProps['options']>([]);
  const [tmpOptions, setTmpOptions] = useState<Array<string>>([]);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState('');

  const {
    data: paginationLogs,
    error,
    isFetching,
    refetch,
  } = useGetDocumentsQuery({ page, pageSize });

  useEffect(() => {
    if (isChanged && map[page] === undefined) {
      //console.log('refetching');
      refetch();
      setMap((prevState) => {
        return { ...prevState, [page]: true };
      });
    }
  }, [page, pageSize, isChanged]);

  useEffect(() => {
    if (searchParams.get('page')) {
      setPage(parseInt(searchParams.get('page')!));
    }
  }, [searchParams]);

  // useEffect(() => {
  //   console.log(paginationLogs, 'pagination logs');
  // }, [paginationLogs]);

  const itemRender: PaginationProps['itemRender'] = (
    _,
    type,
    originalElement
  ) => {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  };

  const showMenu = (row: IDocument, x: number, y: number) => {
    setSelectedDocumentRow(row);
    setPageX(x);
    setPageY(y);
    setMenuVisible(true);
  };
  const handleVisibleChange = (flag: boolean) => {
    setMenuVisible(flag);
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      setMenuVisible(false);
      showDeleteConfirm();
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: 'Delete',
          key: '1',
        },
      ]}
    />
  );

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this document?',
      icon: <ExclamationCircleFilled />,
      //content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        setDeleteModalOpen(false);
        deleteDocument(seletedDocumentRow?.id);
      },
      onCancel() {
        setDeleteModalOpen(false);
      },
    });
  };

  const deleteDocument = async (id: string | undefined) => {
    let url = BASE_URL + '/documents/' + id;

    message.loading({
      content: 'Deleting document...',
      duration: 0,
      key: 'deleting',
    });
    setIsChanged(false);
    await axios
      .delete(url)
      .then((res) => {
        message.destroy('deleting');
        setIsChanged(true);
        setMap({});
      })
      .catch((err) => {
        message.destroy('deleting');
        message.error(err.response.data.message);
      });
  };

  const Columns: any = [
    {
      Header: 'Name',
      Footer: 'Name',
      accessor: 'name',
      Filter: StringColumnFilter,
      Cell: (cell: Cell) => {
        const handleView = async () => {
          const doc = cell.row.original as IDocument;
          setTitle(doc.name);
          setVisible(true);
          setLoading(true);

          setPresignedUrl(doc.presigned_download_url);

          if (doc.type.split('/')[1] === 'pdf') {
            setIsPdf(true);
          } else {
            setIsPdf(false);
          }
        };
        return <a onClick={handleView}>{cell.value}</a>;
      },
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 600,
    },
    {
      Header: 'Date Modified',
      Footer: 'Date Modified',
      accessor: 'last_updated_at',
      Filter: StringColumnFilter,
      Cell: (cell: Cell) => {
        return dayjs(cell.value).format(dateTimeFormat);
      },
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 350,
    },
    {
      Header: 'Size',
      Footer: 'Size',
      accessor: 'size',
      Filter: StringColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 250,
    },
    {
      Header: 'Type',
      Footer: 'Type',
      accessor: 'type',
      Filter: DropdownColumnFilter,
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 250,
    },
    {
      Header: 'Tags',
      Footer: 'Tags',
      accessor: 'tags',
      Filter: StringColumnFilter,
      //disableFilters: true,
      Cell: (cell: Cell) => {
        return (
          <>
            {cell.value.map((tag: string) => (
              <Tag className="mb-1 mr-1">{tag}</Tag>
            ))}
          </>
        );
      },
      canGroupBy: false,
      maxWidth: 400,
      minWidth: 150,
      width: 300,
    },
    {
      Header: 'Processing Status',
      Footer: 'Processing Status',
      accessor: 'processing_status',
      Filter: DropdownColumnFilter,
      Cell: (cell: Cell) => {
        const tagColor: any = {
          'In Progress': 'cyan',
          Completed: 'green',
          Failed: 'red',
        };
        return <Tag color={tagColor[cell.value]}>{cell.value}</Tag>;
      },
      canGroupBy: false,
      maxWidth: 350,
      minWidth: 150,
      width: 250,
    },
  ];

  const uploadProps: UploadProps = {
    name: 'file',
    //multiple: true,
    accept: '.pdf, .csv',
    maxCount: 1,
    beforeUpload: (file) => {
      //setSelectedFile(null);
      const isSupported =
        file.type === 'application/pdf' || file.type === 'text/csv';
      if (!isSupported) {
        message.error(`${file.name} is not supported`);
        return Upload.LIST_IGNORE;
      } else {
        setIsChanged(false);
        setSelectedFile(file);
        //getPresignedURL(file);
      }
      return false;
    },
  };

  const getPresignedURL = async (file: any) => {
    let url = BASE_URL + '/presignedurl';
    let filePath: string = `chat_pdf/${new Date().toISOString()}/${file.name}`;
    message.loading({
      content: 'Uploading...',
      duration: 0,
      key: 'uploading',
    });
    await axios
      .request({
        method: 'get',
        url,
        params: {
          filePath: filePath,
          operation: 'upload',
        },
      })
      .then(async (response) => {
        const presignedUrl = response.data;
        await axios.put(presignedUrl, file).then(async (res) => {
          const sizeinMB = file.size / 10 ** 6;
          const fileSize = sizeinMB.toFixed(2);
          const values = {
            name: file.name,
            type: file.type,
            size: `${fileSize} MB`,
            aws_s3_uri: filePath,
            tags: selectedTags,
          };
          await axios.post(BASE_URL + '/documents', values).then((res) => {
            message.destroy('uploading');
            setIsChanged(true);
            setMap({});
            //getDocuments();
          });
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
        // setLoader(false);
      });
  };

  const getAllTagsList = async () => {
    let url = BASE_URL + '/tags';
    await axios
      .get(url)
      .then((res) => {
        const arr: SelectProps['options'] = [];
        res.data.map((obj: any) => {
          arr.push({
            value: obj.name,
            label: obj.name,
          });

          tmpOptions.push(obj.name);
        });
        setTagsOptions(arr);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getAllTagsList();
  }, []);

  const addTag = async (payload: { [name: string]: string }) => {
    let url = BASE_URL + '/tags';
    await axios
      .post(url, payload)
      .then((res) => {})
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const handleChange = (values: Array<string>) => {
    values.map((val: string) => {
      const value = val.trim();
      if (!tmpOptions.includes(value)) {
        setTmpOptions([...tmpOptions, value]);
        addTag({ name: value });
      }
    });

    setSelectedTags(values);
  };

  const handleDocumentUploadModalOk = async () => {
    if (selectedFile) {
      setDocumentUploadModalOpen(false);
      await getPresignedURL(selectedFile);
      setSelectedFile(null);
      setSelectedTags([]);
    } else {
      message.warning('No valid file Selected for upload!');
    }
  };

  const handleDocumentUploadModalCancel = async () => {
    setDocumentUploadModalOpen(false);
    setSelectedFile(null);
    setSelectedTags([]);
  };

  return (
    <div className="p-4">
      <Button
        icon={<UploadOutlined />}
        onClick={() => setDocumentUploadModalOpen(true)}
      >
        Upload New File
      </Button>

      <Spin spinning={isFetching}>
        {paginationLogs?.data?.length > 0 ? (
          <MaximlTable
            data={paginationLogs?.data}
            columns={Columns}
            loading={false}
            columnFiltering
            onRightClick={showMenu}
          />
        ) : (
          <Empty />
        )}
        {paginationLogs?.data?.length > 0 && (
          <div className="mt-7 flex justify-center w-full">
            <Pagination
              itemRender={itemRender}
              pageSize={paginationLogs?.meta?.itemsPerPage | pageSize}
              total={paginationLogs?.meta?.totalItems}
              defaultCurrent={paginationLogs?.meta?.currentPage | page}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
                navigate(`?page=${page}`);
              }}
            />
          </div>
        )}
      </Spin>

      <div
        className="absolute z-10"
        style={{ top: `${pageY}px`, left: `${pageX}px` }}
      >
        <Dropdown
          overlay={menu}
          onOpenChange={handleVisibleChange}
          open={menuVisible}
          destroyPopupOnHide={true}
        >
          <a onClick={(e) => e.preventDefault()}></a>
        </Dropdown>
      </div>
      <Modal
        visible={documentUploadModalOpen}
        title={<p className="text-[18px] font-bold">Upload document</p>}
        onCancel={handleDocumentUploadModalCancel}
        onOk={handleDocumentUploadModalOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        centered
        width={'40%'}
        //bodyStyle={{ height: '70%' }
      >
        <>
          <div className="flex-col justify-between w-full mt-5 mb-5">
            <p className="text-[16px] font-semibold mb-1">Select File</p>
            <Upload
              {...uploadProps}
              fileList={selectedFile ? [selectedFile] : []}
            >
              <Button icon={<FileOutlined />}>Select</Button>
            </Upload>
          </div>
          <div className="flex-col justify-between w-full">
            <p className="text-[16px] font-semibold mb-1">Select Tags</p>
            <Select
              value={selectedTags}
              mode="tags"
              allowClear
              style={{ width: '90%' }}
              placeholder="Tags"
              onChange={handleChange}
              options={tagsOptions}
            />
          </div>
        </>
      </Modal>

      <DocumentViewModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        setLoading={setLoading}
        title={title}
        isPdf={isPdf}
        presignedUrl={presignedUrl}
      />
    </div>
  );
};

export default Documents;

import { useEffect, useState } from 'react';
import useQuery from '../../hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ExtractedContent,
  FileExtractContentTypes,
  IFileDetails,
  MarkerType,
  Markers,
} from '../../interfaces/File';
import FileViewer from '../../components/FileViewer/FileViewer';
import typesenseSearch from '../../utils/searchHelper';
import { TypesenseSearchContent } from '../../interfaces/typesense';
import { v4 as uuid } from 'uuid';
import { FileExtractContentConfigs, MarkerOptions } from '../../constants';
import { Button, Checkbox, Pagination, Spin } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useAppDispatch } from '../../../state/hooks';
import {
  changeMainMargin,
  toggeleSidepanel,
} from '../../../state/reducers/layout.reducer';
import ISDrawer from '../../components/Drawer/Drawer';
import EquipmentDeatils from '../../components/EquipmentDetails/EquipmentDetails';
import { getFileDetails } from '../../utils/api.helper';
import FileTabs from '../../components/FileTabs/FileTabs';
import { LoadingOutlined } from '@ant-design/icons';
import DynamicTable from '../../components/DynamicTable/DynamicTable';

export interface FileDetailsProps {}
export const FileDetails = ({}: FileDetailsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = useQuery();
  const { id } = useParams();
  const searchKey = queryParams.get('key');
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(
    Number(queryParams.get('page_num'))
  );
  const [initialPageNumber, setInitialPageNumber] = useState<number>();
  const [totalPage, setTotalPage] = useState<number>();

  const [fileData, setFileData] = useState<IFileDetails>();

  const [searchHighlight, setSearchHighlight] =
    useState<TypesenseSearchContent[]>();

  const [markers, setMarkers] = useState<Markers[]>([]);
  const [filteredMarkers, setFilteredMarkers] = useState<Markers[]>([]);

  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState(
    <EquipmentDeatils equipmentId={''} />
  );
  const [isFileDataFetching, setIsFileDataFetching] = useState<boolean>(false);

  const [isCheckboxGroupReady, setIsCheckboxGroupReady] =
    useState<boolean>(false);

  const [markersDefaultOptions, setMarkersDefaultOptions] = useState<string[]>(
    []
  );
  const [markerCheckboxOptions, setMarkerCheckboxOptions] = useState<any[]>([]);

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    dispatch(changeMainMargin(false));
    dispatch(toggeleSidepanel(true));
    setCurrentPageNumber(Number(queryParams.get('page_num')));
    setInitialPageNumber(Number(queryParams.get('page_num')));
    return () => {
      dispatch(changeMainMargin(true));
      dispatch(toggeleSidepanel(false));
    };
  }, []);

  useEffect(() => {
    setIsFileDataFetching(true);
    getFileDetails(id ?? '', currentPageNumber).then((data: IFileDetails) => {
      setFileData(data);
      setIsFileDataFetching(false);
    });
    getHighlights();
  }, [currentPageNumber]);

  useEffect(() => {
    if (fileData) {
      if (!totalPage) setTotalPage(fileData?.page_count);
      setFileExtractedContentToMarkers(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    setDrawingsMarkers();
  }, [searchHighlight]);

  useEffect(() => {
    if (markersDefaultOptions.length && markerCheckboxOptions.length)
      setIsCheckboxGroupReady(true);
  }, [markersDefaultOptions, markerCheckboxOptions]);

  const getHighlights = async () => {
    if (searchKey && currentPageNumber && id) {
      const searchResult: TypesenseSearchContent[] =
        await typesenseSearch.searchByPage(searchKey, currentPageNumber, id);
      setSearchHighlight(searchResult);
    }
  };

  const setDrawingsMarkers = () => {
    const markers: Markers[] = searchHighlight
      ? searchHighlight?.map((highlight: TypesenseSearchContent): Markers => {
          return {
            key: uuid(),
            boundaryRegions: highlight.bounding_region,
            color: 'yellow',
            type: MarkerType.SEARCH_HIGHLIGHTS,
            value: null,
            raw_value: highlight,
            clickHandle: onMarkerClick,
          };
        })
      : [];
    setMarkers((previosState) => [...previosState, ...markers]);
    setFilteredMarkers((state) => [...state, ...markers]);
  };

  const setFileExtractedContentToMarkers = (data?: IFileDetails) => {
    const fileExtractedContents: ExtractedContent[] =
      data?.page?.file_extracted_content ??
      fileData?.page?.file_extracted_content ??
      [];
    if (fileExtractedContents) {
      const defaultOptions: string[] = [];
      let markerOptions: any[] = [];
      const markers = fileExtractedContents.map(
        (content: ExtractedContent): Markers => {
          const markerContent: Markers = {
            key: uuid(),
            boundaryRegions: content.boundary_region,
            color: FileExtractContentConfigs[content.content_type].color,
            type: FileExtractContentTypes[content.content_type],
            value: content.json_value ?? content.string_value,
            raw_value: content,
            clickHandle: onMarkerClick,
            tooltips: prepareTootltips(content),
          };
          if (
            !defaultOptions.includes(
              FileExtractContentTypes[content.content_type]
            )
          ) {
            if (content.content_type !== FileExtractContentTypes.text) {
              defaultOptions.push(
                FileExtractContentTypes[content.content_type]
              );
            }
          }
          return markerContent;
        }
      );
      if (queryParams.get('key')) {
        defaultOptions.push(MarkerType.SEARCH_HIGHLIGHTS);
      }
      markerOptions = MarkerOptions.map((markerOption) => {
        if (defaultOptions.includes(markerOption.value)) {
          markerOption.disabled = false;
        } else if (markerOption.value === 'text') {
          markerOption.disabled = false;
        } else if (
          markerOption.value === MarkerType.SEARCH_HIGHLIGHTS &&
          queryParams.get('key')
        ) {
          markerOption.disabled = false;
        }
        return markerOption;
      });
      setMarkerCheckboxOptions((state) => [...markerOptions]);
      setMarkersDefaultOptions((state) => [...state, ...defaultOptions]);
      setMarkers((state) => [...state, ...markers]);
      const filteredList = markers.filter((marker) => marker.type !== 'text');
      setFilteredMarkers((state) => [...state, ...filteredList]);
    }
  };

  const prepareTootltips = (content: ExtractedContent) => {
    if (content.content_type !== FileExtractContentTypes.table)
      return [{ label: null, value: content.extracted_string_value }];
    else [];
  };

  const fileDetailTabContentClick = (fileContent: ExtractedContent) => {
    if (fileContent.content_type === 'equipment' && fileContent.equipment) {
      setDrawerOpen(false);
      setDrawerTitle(fileContent.extracted_string_value);
      setDrawerContent(
        <EquipmentDeatils equipmentId={fileContent.equipment.id} />
      );
      setDrawerOpen(true);
    } else if (
      fileContent.content_type === 'drawing' &&
      fileContent.referenced_file
    ) {
      window.open(
        `${window.location.origin}/file/${fileContent.referenced_file.id}?page_num=${fileContent.referenced_page.page_number}`,
        '_blank'
      );
    }
  };

  const onMarkerClick = (marker: Markers) => {
    if (marker.type === MarkerType.SEARCH_HIGHLIGHTS) {
      return;
    }
    const markerRawValue: ExtractedContent =
      marker.raw_value as ExtractedContent;
    switch (markerRawValue.content_type) {
      case FileExtractContentTypes.equipment:
        if (markerRawValue.equipment) {
          setDrawerTitle(markerRawValue.extracted_string_value);
          setDrawerContent(
            <EquipmentDeatils equipmentId={markerRawValue.equipment.id} />
          );
          setDrawerOpen(true);
        }
        break;
      case FileExtractContentTypes.table:
        setDrawerTitle('Table Details');
        setDrawerContent(<DynamicTable jsonValue={marker.value} />);
        setDrawerOpen(true);
        break;
    }
  };

  return (
    <>
      {/* {isFileDataFetching ? (
        <div className="h-[100vh] place-content-center flex flex-col items-center justify-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          <h3>Preparing Diagram</h3>
        </div>
      ) : ( */}
      <div className="flex flex-col">
        {fileData && (
          <div className="px-4 text-xl text-black">{fileData?.title}</div>
        )}
        <div className=" flex flex-row items-center justify-between px-4 py-2">
          {isCheckboxGroupReady && (
            <Checkbox.Group
              options={markerCheckboxOptions}
              defaultValue={markersDefaultOptions}
              onChange={(checkedValues: CheckboxValueType[]) => {
                const filteredMarkers = markers.filter((marker: Markers) =>
                  checkedValues.includes(marker.type)
                );
                setFilteredMarkers([...filteredMarkers]);
              }}
            />
          )}

          {totalPage && (
            <Pagination
              simple
              defaultCurrent={initialPageNumber}
              total={totalPage}
              pageSize={1}
              onChange={(pageNumber: number) => {
                setMarkers(() => []);
                setFilteredMarkers(() => []);
                setCurrentPageNumber(pageNumber);
                navigate(
                  `/file/${fileData?.id}?key=${searchKey}&page_num=${pageNumber}`,
                  { replace: true }
                );
              }}
            />
          )}
          {fileData && (
            <Button
              type="primary"
              onClick={() => {
                setDrawerTitle('File Details');
                setDrawerOpen(true);
                setDrawerContent(
                  <FileTabs
                    file={fileData}
                    handleContentClick={(content: ExtractedContent) => {
                      fileDetailTabContentClick(content);
                    }}
                  />
                );
              }}
            >
              Show Details
            </Button>
          )}
        </div>

        {fileData && (
          <FileViewer fileData={fileData} markers={filteredMarkers} />
        )}
        <ISDrawer
          title={drawerTitle}
          open={drawerOpen}
          children={drawerContent}
          onClose={onDrawerClose}
          destroyOnClose={true}
          closable={true}
        />
      </div>
      {/* )} */}
    </>
  );
};

import { useEffect, useState } from 'react';
import { Collapse, DatePicker, Drawer } from 'antd';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  Tooltip,
  TileLayer,
  Polygon,
} from 'react-leaflet';
import './FileViewer.scss';
import dayjs from 'dayjs';
import L, { LatLng } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '../../../hooks/useWindowSize';
import React from 'react';
// import MarkerWithBadge from './MarkerWithBadge';
import {
  IBom,
  ICoordinate,
  IDrawing,
  IPartLocation,
} from '../../../interfaces/drawing.interface';
import { IFileDetails, Markers } from '../../interfaces/File';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface FileViewerProps {
  fileData: IFileDetails;
  markers: Markers[];
}

const FileViewer = (props: FileViewerProps) => {
  const { fileData, markers } = props;
  const [width, height] = useWindowSize();
  const [mapHeight, setMapHeight] = useState(window.innerHeight);
  const [mapWidth, setMapWidth] = useState(window.innerWidth);
  const [imgWidth, setImgWidth] = useState<number>(0);
  const [imgHeight, setImgHeight] = useState<number>(0);

  useEffect(() => {
    setMapHeight(height - 64);
    setMapWidth(width - 282);
  }, [height, width]);

  useEffect(() => {
    createImage();
  }, []);

  useEffect(() => {
    console.log('Marker change ', markers);
  }, [markers]);

  const createImage = () => {
    const image = new Image();
    image.onload = () => {
      setImgWidth(image.width);
      setImgHeight(image.height);
    };
    image.src = fileData.page.page_url;
  };

  const coustomToolTip = (
    tooltips: {
      label: string;
      value: string | number;
    }[]
  ) => {
    return (
      <Tooltip>
        <div className="mx-2 my-1">
          {tooltips.map((tooltip) => (
            <div>
              {tooltip.label && (
                <span className="font-semibold">{tooltip.label} : </span>
              )}
              <span>{tooltip?.value}</span>
            </div>
          ))}
        </div>
      </Tooltip>
    );
  };

  return (
    <div style={{ height: mapHeight }}>
      <MapContainer
        crs={L.CRS.Simple}
        minZoom={-3}
        style={{ height: '100%', width: '100%' }}
        center={[1274, 2500]}
        zoom={-2.5}
        scrollWheelZoom={true}
      >
        <ImageOverlay
          url={fileData.page.page_url}
          bounds={[
            [0, 0],
            [imgHeight, imgWidth],
          ]}
        />
        {markers.map((marker) => (
          <Polygon
            eventHandlers={{
              click: () => {
                if (marker.clickHandle) marker?.clickHandle(marker);
              },
            }}
            key={marker.key}
            pathOptions={{ color: marker.color }}
            positions={marker.boundaryRegions.map((bounding: any) => {
              return [imgHeight - bounding.y * 150, bounding.x * 150];
            })}
          >
            {marker?.tooltips?.length
              ? coustomToolTip(marker?.tooltips ?? [])
              : null}
          </Polygon>
        ))}
      </MapContainer>
    </div>
  );
};
export default FileViewer;

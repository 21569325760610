import axios, { AxiosResponse } from 'axios';
import { PYTHON_SERVER_BASE_URL, BASE_URL } from '../../env';
import { message } from 'antd';

const axiosGet = async (
  url: string,
  params?: { [key: string]: any }
): Promise<AxiosResponse> => {
  return axios.get(url, params);
};

const axiosPOST = async (
  url: string,
  body?: { [key: string]: any }
): Promise<AxiosResponse> => {
  return axios.post(url, body);
};

export const getGraphData = async (requestBody: {
  question: string;
}): Promise<any> => {
  const apiEndpoint = `${PYTHON_SERVER_BASE_URL}/query-knowledge-graph`;
  return axiosPOST(apiEndpoint, requestBody)
    .then((response) => {
      const { data }: { data: any } = response;
      return Promise.resolve(data);
    })
    .catch((response) => {
      console.log('Error: While fetching graph ', response);
      return Promise.reject(response);
    });
};

import React, { FC, useEffect } from 'react';

import '@react-sigma/core/lib/react-sigma.min.css';
import Graph, { MultiDirectedGraph, MultiGraph } from 'graphology';
import {
  ControlsContainer,
  FullScreenControl,
  SearchControl,
  SigmaContainer,
  ZoomControl,
  useLoadGraph,
} from '@react-sigma/core';
import DefaultGraph from './DefaultGraph';
import GraphEventsHandler from './GraphEvents';
import ForceAtlasLayout from './ForceAtlasLayout';
import { LayoutForceAtlas2Control } from '@react-sigma/layout-forceatlas2';
import { SigmaEdgeEventPayload, SigmaNodeEventPayload } from 'sigma/sigma';
interface GraphContainerProps {
  graphData: any;
  onNodeClick?: (event: SigmaNodeEventPayload, nodeDisplayData: any) => void;
  onEdgeClick?: (event: SigmaEdgeEventPayload) => void;
}
const GraphContainer: FC<GraphContainerProps> = ({
  graphData,
  onEdgeClick,
  onNodeClick,
}) => {
  return (
    <SigmaContainer
      settings={{
        renderLabels: true,
        renderEdgeLabels: true,
        defaultEdgeType: 'arrow',
      }}
      style={{ height: '100%' }}
    >
      {/* <RandomCircleGraph /> */}
      <DefaultGraph graphData={graphData} />
      <GraphEventsHandler onEdgeClick={onEdgeClick} onNodeClick={onNodeClick} />
      {/* <ForceAtlasLayout /> */}
      <ControlsContainer position={'bottom-right'}>
        <ZoomControl />
        <FullScreenControl />
        <LayoutForceAtlas2Control />
      </ControlsContainer>
      <ControlsContainer position={'top-right'}>
        <SearchControl
          style={{ width: '250px' }}
          labels={{ placeholder: 'Local Search' }}
        />
      </ControlsContainer>
    </SigmaContainer>
  );
};

export default GraphContainer;

import { Route } from '../interfaces/routes.interface';
import Chat from '../pages/Chat/Chat';
import Documents from '../pages/Documents/Documents';
import Dropbox from '../pages/Dropbox/Dropbox';
import Home from '../pages/Home/Home';

export const routes: Array<Route> = [
  { key: 1, name: 'Home', path: '/', parent_key: '/', element: Dropbox },
  // {
  //   key: 2,
  //   name: 'Athena',
  //   path: '/chats/newchat',
  //   parent_key: '/queries',
  //   element: Chat,
  // },
  {
    key: 3,
    name: 'Athena',
    path: '/documents',
    parent_key: '/documents',
    element: Documents,
  },
];

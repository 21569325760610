import React, { FC, useEffect, useState } from 'react';
import '@react-sigma/core/lib/react-sigma.min.css';
import Graph, { MultiDirectedGraph } from 'graphology';
import { useLoadGraph } from '@react-sigma/core';
import { parse } from 'graphology-gexf';
import { useLayoutForceAtlas2 } from '@react-sigma/layout-forceatlas2';
import { generateRandomHexColor, makeGraph } from '../utils/GraphHelper';
interface DefaultGraphProps {
  graphData: any;
}
const DefaultGraph: FC<DefaultGraphProps> = ({ graphData }) => {
  const loadGraph = useLoadGraph();
  const { positions, assign } = useLayoutForceAtlas2({
    iterations: 100,
    settings: {
      adjustSizes: true,
      barnesHutOptimize: true,
      barnesHutTheta: 0.5,
      edgeWeightInfluence: 1,
      gravity: 1,
      linLogMode: true,
      outboundAttractionDistribution: false,
      scalingRatio: 1,
      slowDown: 4,
      strongGravityMode: true,
    },
  });
  useEffect(() => {
    if (graphData) {
      const graph = new Graph({ type: 'directed' });
      graphData?.nodes?.forEach((node: any) => {
        delete node.attributes?.type;
        try {
          graph.addNode(node.key, {
            ...node.attributes,
            label: node.key,
            size: 25,
            color: node.color_code,
            x: Math.random(),
            y: Math.random(),
          });
        } catch (e) {}
      });
      graphData?.edges?.forEach((edge: any) => {
        try {
          graph.addEdgeWithKey(edge.key, edge.source, edge.target, {
            label: edge.attributes.type,
            size: 5,
          });
        } catch (e) {}
      });
      loadGraph(graph);
      assign();
    }
  }, [graphData, assign, loadGraph]);
  return null;
};

export default DefaultGraph;

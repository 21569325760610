import { User } from './User';
import { TypesenseSearchContent } from './typesense';

export interface IFileDetails {
  id: string;
  created_at: string;
  last_updated_at: string;
  extra_info: null | any; // You can replace 'any' with the actual type if needed.
  title: string;
  file_url: string;
  file_type: string;
  status: string;
  page_count: number;
  created_by: User;
  last_updated_by: User;
  page: PageData;
}

export interface PageData {
  id: string;
  created_at: string;
  last_updated_at: string;
  extra_info: null | any; // You can replace 'any' with the actual type if needed.
  page_number: number;
  page_type: string;
  document_number: null | any; // You can replace 'any' with the actual type if needed.
  document_number_alt: null | any; // You can replace 'any' with the actual type if needed.
  revision_number: null | any; // You can replace 'any' with the actual type if needed.
  page_url: string;
  key_value_pairs: any[]; // You can replace 'any' with the actual type if needed.
  created_by: null | any; // You can replace 'any' with the actual type if needed.
  last_updated_by: null | any; // You can replace 'any' with the actual type if needed.
  file_extracted_content: ExtractedContent[];
}

export interface ExtractedContent {
  id: string;
  created_at: string;
  last_updated_at: string;
  extra_info: null | any; // You can replace 'any' with the actual type if needed.
  equipment?: Equipment;
  referenced_file?: any;
  referenced_page?: any;
  content_type: FileExtractContentTypes;
  json_value: null | any; // You can replace 'any' with the actual type if needed.
  string_value: string;
  extracted_json_value: null | any; // You can replace 'any' with the actual type if needed.
  extracted_string_value: string;
  boundary_region: BoundaryRegion[];
  created_by: null | any; // You can replace 'any' with the actual type if needed.
  last_updated_by: null | any; // You can replace 'any' with the actual type if needed.
}

interface Equipment {
  id: string;
  created_at: string;
  last_updated_at: string;
  equipment_tag: string;
  equipment_description: string;
}

export interface BoundaryRegion {
  x: number;
  y: number;
}

export enum MarkerType {
  SEARCH_HIGHLIGHTS = 'search_highlights',
  EQUIPMENT = 'equipment',
  REF_DOCS = 'reference_documents',
}
export interface Markers {
  key: string;
  boundaryRegions: BoundaryRegion[];
  color: string;
  type: MarkerType | FileExtractContentTypes;
  value: any;
  raw_value: ExtractedContent | TypesenseSearchContent;
  tooltips?: { label: string; value: string | number }[] | any[];
  clickHandle?: (marker: Markers) => void;
}

export enum FileExtractContentTypes {
  table = 'table',
  equipment = 'equipment',
  attribute = 'attribute',
  drawing = 'drawing',
  pipe = 'pipe',
  piping_connection = 'piping_connection',
  text = 'text',
  graph = 'graph',
}

export interface Files {
  id: string;
  created_at: string;
  last_updated_at: string;
  title: string;
  file_url: string;
  file_type: string;
  status: string;
  page_count: number;
  created_by: User;
  last_updated_by: User;
}

import { useEffect, useRef, useState } from 'react';
import { getEquipmentDetails } from '../../utils/api.helper';
import { Equipment, ReferenceFile } from '../../interfaces/Equipment';
import { Avatar, List, Spin, Tabs, TabsProps } from 'antd';
import './FileTabs.scss';
import { FilePdfTwoTone, FolderFilled, TagsFilled } from '@ant-design/icons';
import { ExtractedContent, IFileDetails } from '../../interfaces/File';
import groupBy from 'lodash-es/groupBy';
import Table, { ColumnsType } from 'antd/es/table';
interface FileTabsProps {
  file: IFileDetails;
  handleContentClick: (value: ExtractedContent) => void;
}
const FileTabs: React.FC<FileTabsProps> = ({ file, handleContentClick }) => {
  const fileTabs: TabsProps['items'] = [
    {
      key: '1',
      label: <>Equipments</>,
    },
    {
      key: '2',
      label: <>Connected Drawings</>,
    },
    {
      key: '3',
      label: <>Pipes</>,
    },
    // {
    //   key: '4',
    //   label: <>Tables</>,
    // },
  ];

  const EquipmentTableColumns: ColumnsType<ExtractedContent> = [
    {
      title: 'Tag',
      dataIndex: 'extracted_string_value',
      key: 'extracted_string_value',
      render: (extracted_string_value: string, equipment: ExtractedContent) => (
        <a
          onClick={() => {
            handleContentClick(equipment);
          }}
        >
          {equipment?.equipment?.equipment_tag ??
            extracted_string_value ??
            equipment.string_value}
        </a>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'equipment_description',
      key: 'equipment_description',
      render: (equipment_description: string, equipment) => (
        <>{equipment?.equipment?.equipment_description ?? '-'}</>
      ),
    },
  ];
  const DrawingTableColumns: ColumnsType<ExtractedContent> = [
    {
      title: 'Name',
      dataIndex: 'extracted_string_value',
      key: 'extracted_string_value',
      render: (extracted_string_value: string, drawing: ExtractedContent) => (
        <a
          onClick={() => {
            handleContentClick(drawing);
          }}
        >
          {drawing.extracted_string_value ?? drawing.string_value ?? '-'}
        </a>
      ),
    },
  ];
  const PipeTableColumns: ColumnsType<ExtractedContent> = [
    {
      title: 'Name',
      dataIndex: 'extracted_string_value',
      key: 'extracted_string_value',
      render: (extracted_string_value: string, pipe: ExtractedContent) => (
        <a
          onClick={() => {
            handleContentClick(pipe);
          }}
        >
          {pipe.extracted_string_value ?? pipe.string_value ?? '-'}
        </a>
      ),
    },
  ];
  const [selectedTabKey, changeSelectedTabKey] = useState<string>('1');
  const [listData, setListData] = useState<ExtractedContent[]>([]);
  const [tabContentHeight, setTabContentHeight] = useState<number>(200);
  const [tableColumns, setTableColumns] = useState<any>(EquipmentTableColumns);
  const tableParent = useRef(null);
  const fileExtractedContentGroup = groupBy(
    file.page.file_extracted_content,
    'content_type'
  );
  useEffect(() => {
    if (selectedTabKey === '1' && fileExtractedContentGroup.equipment) {
      setListData([...fileExtractedContentGroup.equipment]);
      setTableColumns(EquipmentTableColumns);
    }
    if (selectedTabKey === '2' && fileExtractedContentGroup.drawing) {
      setListData([...fileExtractedContentGroup.drawing]);
      setTableColumns(DrawingTableColumns);
    }
    if (selectedTabKey === '3') {
      if (fileExtractedContentGroup?.pipe) {
        setListData([...fileExtractedContentGroup?.pipe]);
      } else {
        setListData([]);
      }
      setTableColumns(PipeTableColumns);
    }
  }, [selectedTabKey]);

  useEffect(() => {
    if (tableParent) {
      //@ts-ignore
      const height = tableParent?.current.clientHeight;
      setTabContentHeight(Number(height) - 60);
    }
  }, [tableParent]);

  return (
    <div className="flex flex-col" style={{ height: 'calc(100vh - 109px)' }}>
      <h3>{file.title}</h3>
      <h4 className="mt-4">
        Document Number: {file?.page?.document_number ?? ' N/A '}
      </h4>
      <h4 className="mt-4">
        Vendor Number: {file?.page?.document_number_alt ?? ' N/A '}
      </h4>
      <h4 className="mt-4">
        Revison Number: {file?.page?.revision_number ?? ' N/A '}
      </h4>
      <h4 className="mt-4">Page Number: {file?.page?.page_number}</h4>
      <Tabs
        className="mt-4"
        defaultActiveKey="1"
        items={fileTabs}
        onChange={(key) => {
          changeSelectedTabKey(key);
        }}
      />
      <div
        className="file-tab-list-container flex-1 overflow-scroll"
        ref={tableParent}
      >
        {/* <List
          itemLayout="horizontal"
          dataSource={listData}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <a
                    onClick={() => {
                      handleContentClick(item);
                    }}
                  >
                    {item.extracted_string_value ?? item.string_value}
                  </a>
                }
              />
            </List.Item>
          )}
        /> */}
        <Table
          className="w-[100%]"
          columns={tableColumns}
          dataSource={listData}
          rowKey={'id'}
          pagination={false}
          scroll={{ y: tabContentHeight }}
        />
      </div>
    </div>
  );
};

export default FileTabs;

import { Layout } from 'antd';
import React, { useEffect } from 'react';
import LeftPane from '../../components/LeftPane/LeftPane';
import RightPane from '../../components/RightPane/RightPane';
import { useParams } from 'react-router-dom';
import { ShopFilled } from '@ant-design/icons';

const Home = () => {
  const params = useParams();
  const path = window.location.pathname;
  return (
    <Layout className="h-screen">
      {/* {Object.keys(params).length !== 0 && <LeftPane />} */}
      {/* {window.location.pathname !== '/' && <LeftPane />} */}
      {path === '/' ||
      path === '/intelliscan' ||
      path === '/search' ||
      path === '/knowledgenav' ? (
        <></>
      ) : (
        <LeftPane />
      )}
      {}
      <RightPane />
    </Layout>
  );
};

export default Home;

import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import './Drawer.scss';

export interface DrawerProps {
  title?: React.ReactNode;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  onClose?: () => void;
  open: boolean;
  width?: string;
  children: React.ReactNode;
  destroyOnClose?: boolean;
  mask?: boolean;
  closable?: boolean;
}
const ISDrawer = ({
  title = <></>,
  placement = 'right',
  onClose,
  open,
  width,
  children,
  destroyOnClose = true,
  mask = true,
  closable = true,
}: DrawerProps) => {
  const [localOpen, setLocalOpen] = useState<boolean>(open);
  useEffect(() => {
    console.log('Drawer open ', open);
    setLocalOpen(open);
  }, [open]);
  return (
    <Drawer
      className=".is-drawer"
      title={title}
      placement={placement}
      onClose={() => {
        setLocalOpen(false);
        onClose ? onClose() : null;
      }}
      open={localOpen}
      width={width ?? '40%'}
      children={children}
      destroyOnClose={destroyOnClose}
      closable={closable}
      mask={mask}
    />
  );
};

export default ISDrawer;

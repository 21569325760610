import React, { useState } from 'react';
import { IMessage } from '../../../interfaces/message.interface';
import { FilePdfTwoTone } from '@ant-design/icons';
import { Modal, Popover, Spin, Table, message } from 'antd';
import { IDocument } from '../../../interfaces/documents.interface';
import axios from 'axios';
import './style.scss';
import Papa from 'papaparse';
import DocumentViewModal from '../../Documents/Components/DocumentViewModal';

interface SourcesProps {
  sources: Array<IDocument>;
}

const MessageSources = ({ sources }: SourcesProps) => {
  const [title, setTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const [isPdf, setIsPdf] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState('');

  const handleDocumentCardClick = async (doc: IDocument) => {
    setTitle(doc.name);
    setVisible(true);
    setPopoverVisible(false);
    setLoading(true);

    setPresignedUrl(doc.presigned_download_url);

    if (doc.type.split('/')[1] === 'pdf') {
      setIsPdf(true);
    } else {
      setIsPdf(false);
    }
  };

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <>
      <div className="flex">
        <div
          className="flex items-center mr-2 cursor-pointer"
          onClick={async () => {
            handleDocumentCardClick(sources[0]);
          }}
        >
          <FilePdfTwoTone twoToneColor={'red'} className="text-[18px] mr-1" />
          <div
            className="max-w-[300px] truncate hover:text-blue-500"
            title={sources[0].name}
          >
            {sources[0].name}
          </div>
        </div>
        {sources.length > 1 && (
          <Popover
            open={popoverVisible}
            onOpenChange={handlePopoverVisibleChange}
            placement="right"
            trigger="hover"
            content={
              <div className="">
                {sources.slice(0, sources.length).map((doc: IDocument) => {
                  return (
                    <div
                      className="flex items-center mb-2 cursor-pointer"
                      onClick={() => {
                        handleDocumentCardClick(doc);
                      }}
                    >
                      <FilePdfTwoTone
                        twoToneColor={'red'}
                        className="text-[18px] mr-1"
                      />

                      <div className="hover:text-blue-500 ">{doc.name}</div>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="text-blue-400 cursor-pointer">
              +{sources.length - 1} More
            </div>
          </Popover>
        )}
      </div>

      <DocumentViewModal
        visible={visible}
        setVisible={setVisible}
        loading={loading}
        setLoading={setLoading}
        title={title}
        isPdf={isPdf}
        presignedUrl={presignedUrl}
      />
    </>
  );
};

export default MessageSources;

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Empty,
  Spin,
  Table,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BASE_URL,
  CLOUDINARY_API_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUDINARY_URL,
} from '../../env';
import MapView from './Components/MapView';
import axios, { CancelTokenSource } from 'axios';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { useAppDispatch } from '../../state/hooks';
import { setDrawingsRefreshRequired } from '../../state/reducers/drawingReducer';
import { IDrawing } from '../../interfaces/drawing.interface';
import useWindowSize from '../../hooks/useWindowSize';
import Split from 'react-split';
import './split.css';

let timerId: TimeoutId | null = null;
let controller: AbortController | null = null;

const Drawings = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [width, height] = useWindowSize();
  const [drawingData, setDrawingData] = useState<IDrawing>({} as IDrawing);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  //const [retryTimerId, setRetryTimerId] = useState<TimeoutId | null>(null);
  //const [controller, setController] = useState<AbortController | null>(null);

  const columns = [
    {
      title: <span style={{ fontSize: '12px' }}>UMC</span>,
      dataIndex: 'umc',
      key: 'umc',
      render: (text: string) => <p className="text-[10px]">{text}</p>,
    },
    {
      title: <span style={{ fontSize: '12px' }}>Part Number</span>,
      dataIndex: 'part_number',
      key: 'part_number',
      render: (text: string) => <p className="text-[10px]">{text}</p>,
    },
    {
      title: <span style={{ fontSize: '12px' }}>Part Name</span>,
      dataIndex: 'part_name',
      key: 'part_name',
      render: (text: string) => <p className="text-[10px]">{text}</p>,
    },
    {
      title: <span style={{ fontSize: '12px' }}>Required Quantity</span>,
      dataIndex: 'required_quantity',
      key: 'required_quantity',
      render: (text: string) => <p className="text-[10px]">{text}</p>,
    },
    {
      title: <span style={{ fontSize: '12px' }}>Available Quantity</span>,
      dataIndex: 'available_quantity',
      key: 'available_quantity',
      render: (text: string) => <p className="text-[10px]">{text}</p>,
    },
  ];

  const getDrawingDetails = () => {
    controller = new AbortController();

    //let url = `https://447d4184-f997-4a2e-a1b7-8b94a7ed5160.mock.pstmn.io/drawings/6d0d8c3b-aa8a-4dbe-8da2-317893e80b62`;

    let url = BASE_URL + '/drawings/' + params.id;
    axios
      .get(url, { signal: controller.signal })
      .then((res) => {
        console.log(res.data, 'get drawing response', new Date());
        // In case of empty response, retry the request for every 3 seconds
        if (Object.keys(res.data).length == 0) {
          console.log('drawing inside empty resp case');
          //setLoading(true);
          timerId = setTimeout(() => {
            getDrawingDetails();
          }, 3000);
        } else {
          console.log('drawing inside actual resp case');
          setLoading(false);
          setDrawingData(res.data);
          // if (retryTimerId) {
          //   clearTimeout(retryTimerId);
          //   setRetryTimerId(null);
          // }
          controller?.abort();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // if (retryTimerId) {
        //   clearTimeout(retryTimerId);
        //   setRetryTimerId(null);
        // }
        controller?.abort();
      });
  };

  useEffect(() => {
    if (params.id) {
      console.log(params.id, 'drawing id changed');
      getDrawingDetails();
    }
    return () => {
      console.log('drawing page changed');
      setDrawingData({} as IDrawing);
      setLoading(true);
      console.log(timerId, 'retry timer called');
      if (timerId) {
        clearTimeout(timerId);
        timerId = null;
      }

      controller?.abort();
    };
  }, [params]);

  useEffect(() => {
    return () => {
      console.log('drawings unmounted changed');
      if (timerId) {
        clearTimeout(timerId);
        timerId = null;
      }

      controller?.abort();
    };
  }, []);

  useEffect(() => {
    console.log(drawingData, 'drawing data changed');
  }, [drawingData]);

  const uploadNewDrawing = async (
    fileName: string,
    fileType: string,
    cloudinaryUrl: string
  ) => {
    let newUrl = cloudinaryUrl;
    if (fileType === 'application/pdf') {
      const extensionIndex = cloudinaryUrl.lastIndexOf('.');
      newUrl = cloudinaryUrl.substring(0, extensionIndex) + '.png';
    }

    let url = BASE_URL + `/drawings`;
    let values = {
      title: fileName,
      cloudinary_url: newUrl,
    };
    await axios
      .post(url, values)
      .then((res) => {
        console.log(res.data, 'upload new drawing resp');
        setUploading(false);
        dispatch(setDrawingsRefreshRequired(true));
        navigate(`/drawings/${res.data.id}`); // navigating to the newly created drawing details page
      })
      .catch((err) => {
        console.log(err, 'upload new drawing error');
      });
  };

  const props: UploadProps = {
    name: 'file',
    action: CLOUDINARY_URL,
    data: (file: UploadFile) => ({
      upload_preset: CLOUDINARY_UPLOAD_PRESET,
      api_key: CLOUDINARY_API_KEY,
      file,
    }),
    multiple: false,
    showUploadList: false,
    accept: 'application/pdf, image/png',
    beforeUpload: (file) => {
      setUploading(true);
      const isSupported =
        file.type === 'application/pdf' || file.type === 'image/png';
      if (!isSupported) {
        message.error(`${file.name} is not supported`);
        setUploading(false);
      }
      return isSupported;
    },
    onChange(info) {
      const file = info.file;
      if (file.status !== 'uploading') {
        console.log(file, 'uploaded file');
      }
      if (file.status === 'done') {
        message.success(`${file.name} file uploaded successfully`);
        uploadNewDrawing(file.name, file.type!, file.response.secure_url);
      } else if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
    },
  };

  if (params.id === undefined) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Empty />
      </div>
    );
  } else {
    return (
      <>
        <div className="flex items-center justify-between m-5">
          <p className="font-bold text-[18px]">{drawingData?.title}</p>
          <Upload {...props}>
            <Button
              type="primary"
              icon={<UploadOutlined />}
              loading={uploading}
              disabled={loading}
            >
              New Drawing
            </Button>
          </Upload>
        </div>

        {loading ? (
          <div className="h-96 flex items-center justify-center w-full">
            <Spin size="large" />
          </div>
        ) : (
          Object.keys(drawingData).length > 0 && (
            <Split className="split">
              <div className="w-fit mx-2">
                <MapView drawingData={drawingData} />
              </div>

              <div
                className=" mb-3 mr-2 overflow-y-auto"
                style={{ height: height - 150 }}
              >
                <p className="font-semibold text-center text-[18px] underline underline-offset-2">
                  Bill of Materials
                </p>
                <Table
                  dataSource={drawingData['bom'] || []}
                  columns={columns}
                  pagination={false}
                />
              </div>
            </Split>
          )
        )}
      </>
    );
  }
};

export default Drawings;

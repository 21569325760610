import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import { logout } from '../../../state/reducers/authReducer/authReducer';
import Avatar from 'antd/es/avatar/avatar';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(({ auth }) => auth.user);
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span className="flex flex-col">
          <span className="font-semibold">{user.username}</span>
          {user.email}
        </span>
      ),
    },
    {
      type: 'divider',
    },
    {
      label: <span>Log Out</span>,
      key: '2',
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '2') {
      dispatch(logout(''));
    }
  };

  return (
    <div className="flex items-center justify-between">
      <Dropdown menu={{ items, onClick }} trigger={['click']}>
        <div
          onClick={(e) => e.preventDefault()}
          className="flex items-center cursor-pointer"
        >
          <Avatar
            className="hover:scale-110 transition ease-in-out delay-100"
            style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
          >
            {user.username?.substring(0, 1)}
          </Avatar>
          <span className="ml-2">{user.username}</span>
        </div>
      </Dropdown>
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Row } from "react-table";

const { Option } = Select;

export const DropdownColumnFilter = ({ column }: any) => {
  const { id, preFilteredRows, filterValue = [], setFilter } = column;
  const [uniqueItems, setuniqueItems] = useState<any>([]);

  useEffect(() => {
    const items = new Set<string>();
    preFilteredRows.forEach((row: Row) => {
      const values = row.values[id];
      if (values instanceof Array) {
        values.map((val: any) => {
          items.add(val);
        });
      } else {
        if (
          row.values[id] !== null &&
          row.values[id] !== "" &&
          row.values[id] !== undefined
        ) {
          items.add(values);
        }
      }
    });

    setuniqueItems(items);
  }, [preFilteredRows]);

  const children: any = [];
  uniqueItems.forEach((item: any) => {
    children.push(<Option key={item}>{item}</Option>);
  });

  function handleChange(value: any) {
    setFilter(value);
  }

  return (
    <div>
      <Select
        className="font-normal"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={filterValue}
        onChange={handleChange}
      >
        {children}
      </Select>
    </div>
  );
};

export const SimpleDropdownColumnFilter = ({ column }: any) => {
  const { id, preFilteredRows, filterValue = [], setFilter } = column;

  const [uniqueItems, setuniqueItems] = useState<Set<string>>(new Set([]));

  useEffect(() => {
    const items = new Set<string>();
    preFilteredRows.forEach((row: Row) => {
      if (row.values[id] !== null && row.values[id] !== "") {
        items.add(row.values[id]);
      }
    });

    setuniqueItems(items);
  }, [preFilteredRows]);

  const children: any = [];
  uniqueItems.forEach((item: any) => {
    children.push(<Option key={item}>{item}</Option>);
  });

  function handleChange(value: any) {
    setFilter(value);
  }

  return (
    <div className="an-mt-2">
      <Select
        className="an-font-normal"
        allowClear
        style={{ width: "100%" }}
        placeholder="Please select"
        defaultValue={filterValue}
        onChange={handleChange}
      >
        {children}
      </Select>
    </div>
  );
};

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Authentication from './pages/Authentication/Authentication';
import { useAppSelector } from './state/hooks';
import RightPane from './components/RightPane/RightPane';
import Home from './pages/Home/Home';
import Dropbox from './pages/Dropbox/Dropbox';
import Chat from './pages/Chat/Chat';
import { socket } from './socket';
import Documents from './pages/Documents/Documents';
import Drawings from './pages/Drawings/Drawings';
import GlobalSearch from './intelliscan/pages/GlobalSearch/GlobalSearch';
import SearchResults from './intelliscan/pages/SearchResult/SearchResults';
import { FileDetails } from './intelliscan/pages/FileDetails/FileDetails';
import FilesList from './intelliscan/components/FileList/FilesList';
import EquipmentList from './intelliscan/components/EquipmentList/EquipmentList';
import KnowledgeGraph from './knowledge_graph/pages/KnowledgeHome/KnowledgeHome';

const App = () => {
  const loggedIn = useAppSelector((state: any) => state.auth.loggedIn);
  const authChecker = loggedIn ? <Home /> : <Authentication />;

  // useEffect(() => {
  //   if (loggedIn) {
  //     try {
  //       socket.on('connect', () => {
  //         console.log('connected to web socket:', socket.id);
  //       });

  //       socket.on('connect_error', (err) => {
  //         if (err.message === 'Unauthorized') {
  //           socket.auth = {
  //             authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  //             'x-tenant-id': localStorage.getItem('x-tenant-id'),
  //           };
  //           socket.connect();
  //         }
  //       });

  //       socket.on('onUpdate', (data) => {
  //         console.log('update:', data);
  //       });
  //       // join tenant room

  //       // listener for global event i.e project crud, workflow and automation rules etc
  //     } catch (error: any) {
  //       console.log('error data', error.message);
  //     }
  //   }

  //   return () => {
  //     // leave to project room

  //     socket.disconnect();
  //   };
  // }, [loggedIn]);
  return (
    <>
      <Routes>
        <Route path="/" element={authChecker}>
          <Route path="/" element={<Dropbox />} />
          <Route path="/intelliscan" element={<GlobalSearch />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/file/:id" element={<FileDetails />} />
          <Route path="/chats/newchat" element={<Chat key={'newchat'} />} />
          <Route path="/chats/:id" element={<Chat />} />
          <Route path="/drawings" element={<Drawings />} />
          <Route path="/drawings/:id" element={<Drawings />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/files" element={<FilesList />} />
          <Route path="/equipments" element={<EquipmentList />} />
          <Route path="/knowledgenav" element={<KnowledgeGraph />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
